/** @format */

import React from "react";
import NewsletterTwentyEight from "../component/NewsletterTwentyEight";

function NewsletterTwentyEightContainer() {
  return <NewsletterTwentyEight />;
}

export default NewsletterTwentyEightContainer;
