import React from "react";
import { Helmet } from "react-helmet";
import Intro from "./Intro";
import MetaTags from "react-meta-tags";
import Section_1 from "./Section_1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";

function MaterialityAssessment() {
  return (
    <>
      <MetaTags>
        <title>Double Materiality Assessment Software With Advance Reporting</title>
        <meta
          name="description"
          content="Updapt ESG Double Materiality Assessment Software Evaluates both the financial impact of sustainability factors and the company's ESG ."
        />
        <meta
          property="og:title"
          content="ESG Double Materiality Assessment Software With Advance Reporting"
        />
        <meta property="og:image" content="%PUBLIC_URL%/static/media/banner.jpg" />
      </MetaTags>
      <Helmet>
        <link
          rel="alternate"
          hreflang="x-default"
          href="https://www.updapt.com/solutions/double-materiality-assessment"
        />
        <link
          rel="alternate"
          hreflang="en-AE"
          href="https://www.updapt.com/solutions/double-materiality-assessment"
        />
      </Helmet>
      <Intro />
      <Section_1 /> <Section2 /> <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </>
  );
}

export default MaterialityAssessment;
