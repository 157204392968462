import React from "react";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import NewBanner from "./NewBanner";
import UpdaptDescription from "./UpdaptDescription";
import Products from "./Products";
import Benefits from "./Benefits";
import Solutions from "./Solutions";
import Resources from "./Resources";
import GetDemoBanner from "./GetDemoBanner";
import GetFreeTrialBanner from "./GetFreeTrialBanner";
import Demo from "../../Demo";
import Clients from "./Clients";
import SecondaryDescription from "./SecondaryDescription";
import structuredData from "./structuredData.json";

function NewLandingPage({ productsList, resourceBlogs }) {
  return (
    <>
      <MetaTags>
        <title>Sustainability and ESG Reporting Software for Middle East</title>
        <meta
          name="description"
          content="Updapt is leading ESG Reporting Software dedicatedly designed For middle east countries that Include data management, emission management. It suits all industries."
        />
        <meta
          property="og:title"
          content="Sustainability and ESG Reporting Software for Middle East"
        />
        <meta property="og:image" content="%PUBLIC_URL%/static/media/banner.jpg" />
      </MetaTags>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href="https://www.updapt.com/uae-esg-reporting-software/ae/en/"
        />
        <script type="application/ld+json" async>
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <NewBanner />
      <UpdaptDescription />
      <Solutions />
      <SecondaryDescription />
      <div className="new-landing-page-slider">
        <Clients />
      </div>
      <Products productsList={productsList} />
      <GetDemoBanner />
      <Benefits />
      <GetFreeTrialBanner />
      <Resources resourceBlogs={resourceBlogs} />
      <Demo />
    </>
  );
}

export default NewLandingPage;
