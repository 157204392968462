/** @format */

import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";

function NewsletterTwentyEight() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter January 2025
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-twenty-seven-container">
        <MetaTags>
          <title>
            Switzerland Introduces Mandatory Net-Zero Roadmaps for Companies.
          </title>
          <meta
            name="description"
            content="The Swiss government has initiated a consultation to enhance corporate sustainability disclosure regulations, mandating that companies develop net-zero roadmaps aligned with the nation's goal of achieving net-zero greenhouse gas emissions by 2050"
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Switzerland Introduces Mandatory Net-Zero Roadmaps for Companies.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/Switzerland.jpg").default
            }
            alt="benchmark"
          />
        </figure>
        <p>
          The Swiss government has initiated a consultation to enhance corporate
          sustainability disclosure regulations, mandating that companies
          develop "net-zero roadmaps" aligned with the nation's goal of
          achieving net-zero greenhouse gas emissions by 2050. This proposal
          seeks to amend the existing Ordinance on Climate Disclosures, which
          came into effect in January 2024, requiring large companies and
          financial institutions to report on climate-related factors such as
          greenhouse gas emissions, climate risks, and transition plans.
        </p>
        <p>
          A significant aspect of the new proposal is the expansion of the
          reporting scope. The current threshold applies to companies with over
          500 employees; the amendment proposes lowering this to include
          companies with at least 250 employees, CHF 25 million (€26 million) in
          total assets, or CHF 50 million (€52 million) in sales. Additionally,
          companies would be permitted to fulfill their climate reporting
          obligations by aligning disclosures with internationally recognized
          standards such as the International Sustainability Standards Board
          (ISSB) or the European Sustainability Reporting Standards (ESRS).
        </p>
        <p>
          The proposal also stipulates that climate reporting be provided in
          electronic formats that are both human- and machine-readable,
          facilitating publication on international platforms. These measures
          underscore Switzerland's commitment to enhancing corporate
          transparency and aligning with global sustainability standards
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Companies can adopt Switzerland’s climate disclosure rules by
            assessing emissions, aligning with ISSB or ESRS frameworks, and
            leveraging digital tools for reporting. Training employees,
            consulting experts, and integrating net-zero goals into strategies
            are vital.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada Strengthens Climate Goals with New 2035 Emissions Reduction
          Target.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/canada.jpg").default}
              alt="Esg ratings"
            />
          </figure>
        </div>
        <p>
          The Canadian government has set an ambitious new climate target,
          committing to reduce greenhouse gas emissions by 45–50% below 2005
          levels by 2035. This builds upon the nation’s current 2030 goal of a
          40–45% reduction and aligns with its long-term plan to achieve
          net-zero emissions by 2050. The government highlights this as a
          practical and forward-looking approach to address climate challenges
          while balancing economic priorities.
        </p>
        <p>
          This updated goal demonstrates Canada’s dedication to climate
          leadership, with a focus on implementing innovative technologies and
          sustainable practices. It reinforces ongoing efforts to transition
          toward cleaner energy sources and strengthen the resilience of key
          sectors. The plan underscores the nation’s commitment to meeting
          international environmental standards and supporting global
          sustainability initiatives.
        </p>
        <p>
          The Canadian Net-Zero Emissions Accountability Act will guide progress
          toward the new target, requiring the development of a detailed plan by
          2029. This plan will outline strategies and measures to achieve these
          reductions, ensuring a comprehensive and transparent pathway to a more
          sustainable future.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            This step by the Canadian government provides a clear and structured
            framework for emissions reductions, enabling companies to align
            their strategies with national climate goals. It creates
            opportunities for businesses to innovate and invest in cleaner
            technologies, boosting competitiveness in the growing green economy.
            By fostering a predictable policy environment, it encourages
            private-sector participation in sustainability initiatives,
            positioning companies to benefit from global demand for low-carbon
            products and solutions.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          European Commission Issues Guidance to Streamline EU Taxonomy
          Implementation.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/europe.jpg").default}
            alt="deforestation-in-Amazon"
          />
        </figure>
        <p>
          The European Commission has released a comprehensive set of frequently
          asked questions (FAQs) to assist investors in navigating the EU
          Taxonomy, a framework that classifies sustainable economic activities.
          This initiative aims to enhance the Taxonomy's usability and alleviate
          the administrative burden on companies adhering to the EU's
          sustainable finance regulations.
        </p>
        <p>
          The EU Taxonomy is integral to the EU Action Plan on Sustainable
          Finance, providing a standardised system to identify economic
          activities that significantly contribute to environmental objectives
          without causing substantial harm to others. These objectives encompass
          climate change mitigation and adaptation, sustainable use and
          protection of water and marine resources, transition to a circular
          economy, pollution prevention and control, and the protection and
          restoration of biodiversity and ecosystems.
        </p>
        <p>
          The newly published FAQs offer technical clarifications on various
          aspects of the Taxonomy, including general requirements,
          interoperability with the Corporate Sustainability Reporting
          Directive's European Standards for Sustainability Reporting,
          verification and assurance obligations, and specific technical
          screening criteria for each environmental objective. Additionally, the
          FAQs address the 'Do No Significant Harm' criteria, ensuring that
          activities contributing to one objective do not adversely impact
          others.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The European Commission's guidance on the EU Taxonomy simplifies
            sustainable finance compliance, enabling investors and businesses to
            classify activities aligning with environmental goals efficiently.
            This step promotes transparency, reduces administrative complexity,
            and ensures consistency across sustainability reporting frameworks.
            By clarifying technical requirements, it fosters informed investment
            decisions and accelerates the transition to a more sustainable,
            green economy.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          UK Strengthens ESG Framework with Adoption of IFRS Sustainability
          Standards
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/uk.jpg").default}
            alt="uae"
          />
        </figure>
        <p>
          The UK has taken a significant step toward enhancing sustainability
          reporting by proposing the adoption of the IFRS Sustainability
          Disclosure Standards, IFRS S1 and S2, for its companies. This
          initiative aligns the UK with other leading economies that are
          committing to standardized, transparent ESG reporting to drive global
          sustainability efforts.
        </p>
        <p>
          A key recommendation includes extending the "climate-first"
          implementation phase to two years. This allows companies to prioritize
          climate-related disclosures initially, ensuring a smoother adaptation
          process before incorporating broader sustainability risks. This phased
          approach is designed to balance the urgency of climate action with the
          practicalities of comprehensive reporting.
        </p>
        <p>
          The next phase involves a government review and consultation on
          exposure drafts, anticipated in early 2025. Once finalized, these
          standards will integrate into the UK's regulatory framework,
          reinforcing the country’s position as a leader in sustainability and
          ESG. This move underscores the commitment to consistent, high-quality
          sustainability disclosures, crucial for advancing corporate
          accountability and fostering a sustainable economic transition.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            To prepare for the adoption of IFRS Sustainability Standards,
            companies should enhance their ESG frameworks, focusing on robust
            data collection and transparent reporting processes. Embracing
            technology for efficient tracking, integrating sustainability into
            corporate strategy, and fostering cross-department collaboration are
            key. Training leadership and teams on evolving standards and
            proactively engaging stakeholders will ensure alignment. By
            embedding sustainability at their core, companies can seamlessly
            adapt, driving both compliance and long-term value creation.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Thailand Issues First Sustainability-Linked Bond to Support
          Environmental Goals.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/thailand.jpg").default}
            alt="cop29"
          />
        </figure>

        <p>
          Thailand has launched its first sustainability-linked bond (SLB),
          raising 30 billion baht (around $865 million). This 15-year bond
          connects its interest rate to the achievement of specific
          environmental goals, signaling a commitment to integrating
          sustainability into financial strategies. This issuance marks a
          significant step as the first SLB in Asia and the third globally.
        </p>
        <p>
          The bond’s terms are tied to two targets: reducing greenhouse gas
          emissions by 30% by 2030 from projected levels and increasing
          registrations of zero-emission passenger vehicles to 440,000 units
          annually by the same year. These conditions create financial
          incentives for the country to meet its sustainability objectives, with
          interest rates potentially adjusting based on the success or failure
          to achieve the goals.
        </p>
        <p>
          Investor demand for the bond was strong, exceeding the initial target
          by 2.8 times and leading to an increased issuance amount. This
          reflects the growing interest in sustainable finance in the region.
          The government has also indicated plans for further
          sustainability-linked bonds in the coming years.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The sustainability-linked bond will support Thailand in achieving
            its environmental goals by directly tying financial incentives to
            measurable progress. By focusing on reducing emissions and
            increasing zero-emission vehicle registrations, the bond encourages
            sustainable practices, enhances investor confidence, and aligns the
            country’s financial strategies with its long-term development
            objectives.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwentyEight;
