/** @format */

// /** @format */

// import November from "../../assets/pdf/November2020Newsletter.pdf";
// import December from "../../assets/pdf/December2020Newsletter.pdf";
// import January from "../../assets/pdf/January2021Newsletter.pdf";
// import February from "../../assets/pdf/February2021Newsletter.pdf";
// import March from "../../assets/pdf/March2021Newsletter.pdf";
// import April from "../../assets/pdf/April2021Newsletter.pdf";
// import May from "../../assets/pdf/May2021Newsletter.pdf";
// import June from "../../assets/pdf/June2021Newsletter.pdf";
// import July from "../../assets/pdf/July2021Newsletter.pdf";
// import August from "../../assets/pdf/August2021Newsletter.pdf";
// import September from "../../assets/pdf/September2021Newsletter.pdf";
// import October from "../../assets/pdf/October2021Newsletter.pdf";
// import November2021 from "../../assets/pdf/November2021Newsletter.pdf";
// import December2021 from "../../assets/pdf/December2021.pdf";
// import January2022 from "../../assets/pdf/Newsletter - January2022.pdf";
// import February2022 from "../../assets/pdf/February2022Newsletter.pdf";
// import March2022 from "../../assets/pdf/March2022Newsletter.pdf";
// import April2022 from "../../assets/pdf/April2022Newsletter.pdf";
// import May2022 from "../../assets/pdf/May2022Newsletter.pdf";
// import June2022 from "../../assets/pdf/June2022Newsletter.pdf";
// import July2022 from "../../assets/pdf/July2022Newsletter.pdf";
// import August2022 from "../../assets/pdf/August2022Newsletter.pdf";
// import September2022 from "../../assets/pdf/September2022Newsletter.pdf";

// const resourceValues = [
//   {
//     title: "November 2020",
//     image: "Newsletter/nov-2020.svg",
//     url: November,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov, 2020",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "December 2020",
//     image: "Newsletter/dec 2020.svg",
//     url: December,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec, 2020",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "January 2021",
//     image: "Newsletter/jan 2021.svg",
//     url: January,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jan, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "February 2021",
//     image: "Newsletter/feb 2021.svg",
//     url: February,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Feb, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "March 2021",
//     image: "Newsletter/mar 2021.svg",
//     url: March,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Mar, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "April 2021",
//     image: "Newsletter/apr 2021.svg",
//     url: April,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Apr, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "May 2021",
//     image: "Newsletter/may 2021.svg",
//     url: May,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "June 2021",
//     image: "Newsletter/june 2021.svg",
//     url: June,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jun, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "July 2021",
//     image: "Newsletter/july 2021.svg",
//     url: July,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jul, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "August 2021",
//     image: "Newsletter/aug2021.svg",
//     url: August,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Aug, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "September 2021",
//     image: "Newsletter/sept 2021.svg",
//     url: September,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Sep, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "October 2021",
//     image: "Newsletter/oct 2021.svg",
//     url: October,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "oct, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "November 2021",
//     image: "Newsletter/nov 2021.svg",
//     url: November2021,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "December 2021",
//     image: "Newsletter/dec 2021.svg",
//     url: December2021,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec, 2021",
//     status: "2 min read",
//     type: "newsletter",
//   },

//   {
//     title: "January 2022",
//     image: "Newsletter/jan 2022.svg",
//     url: January2022,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jan, 2022",
//     status: "2 min read",
//     type: "newsletter",
//   },
//   {
//     title: "SDGs 101",
//     url: "sdgs-101",
//     image: "Blogs/blog-1.jpg",

//     content: [
//       " A Guide to understanding the UN assigned SDG’s 1. No Poverty..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec 22, 2019",
//     status: "6 min read",
//     type: "blogs",
//   },
//   {
//     title: "Sustainability- Towards a Better Future",
//     url: "sustainability-let-s-make-it-better",
//     image: "Blogs/blog-2.jpg",

//     content: [
//       "“Climate Change”, “Sustainable Development Goals”, “The Paris Agreem..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec 23, 2019",
//     status: "4 min read",
//     type: "blogs",
//   },
//   {
//     title: "Responsible Companies for a Responsible Nation",
//     url: "responsible-companies-and-a-responsible-nation-an-insight-to-csr-in-india",
//     image: "Blogs/blog-3.jpg",

//     content: [
//       " An insight to CSR in India Corporate Social Responsibility (CSR) has ambiguous defini..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec 24, 2019",
//     status: "4 min read",
//     type: "blogs",
//   },
//   {
//     title: "Sustainability and the Fashion Industry",
//     url: "sustainability-and-the-fashion-industry",
//     image: "Blogs/blog-4.svg",

//     content: [
//       " We often read reports of incidence of forest fires destroying natural habittats and errat..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec 25, 2019",
//     status: "4 min read",
//     type: "blogs",
//   },
//   {
//     title: "How the SDGs reinforce CSR in india",
//     url: "the-road-to-sustainable-development-how-the-sdgs-are-reinforced-in-india",
//     image: "Blogs/blog-6.jpg",

//     content: [
//       "CSR funding can help us tap into resources that are required for environmental and so..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jan 3, 2020",
//     status: "4 min read",
//     type: "blogs",
//   },
//   {
//     title: "Sea level rise and its impact",
//     url: "sea-level-rise-and-it-s-impact",
//     image: "Blogs/blog-7.jpg",

//     content: [
//       "The oceans make up about 70% of our planet. Oceans sequester large amounts of..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 4, 2020",
//     status: "2 min read",
//     type: "blogs",
//   },

//   {
//     title: "World Environment Day",
//     url: "world-environment-day",
//     image: "Blogs/blog-8.jpg",

//     content: [
//       "We rely on nature for everything- the air we breathe, the water we consume, energy, fo..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jun 5, 2020",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title: "Sustainability and the Pandemic",
//     url: "sustainability-and-the-pandemic",
//     image: "Blogs/blog-4.jpg",

//     content: [
//       "The Corona-virus has been wreaking havoc across all parts of the world since the start..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jun 19, 2020",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title: "Why Diversity is integral to a sustainable Business?",
//     url: "why-diversity-in-integral-to-a-sustainable-business",
//     image: "Blogs/blog-3a.jpg",

//     content: [
//       "The world we live in is divided; diversity exists in the form of culture,race and soci..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jun 27, 2020",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title: "The Need for a sustainable Business Behavior",
//     url: "the-need-for-a-sustainable-business-behavior",
//     image: "Blogs/blog-2a.jpg",

//     content:
//       "The world has faced some unprecedented challenges during the recent year,erratic..",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec 9, 2020",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title: "What happens when we discard our phone for a 'Smarter' one",
//     url: "what-happens-when-we-discard-our-phone-for-a-smarter-one",
//     image: "Blogs/blog-8a.jpg",

//     content: [
//       "The market for electrical and electronic equipment has seen an exponential growth..",
//     ],
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jan 08, 2021",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title: "Understanding Net Zero Emission Target",
//     url: "what-do-net-zero-emission-targets-mean",
//     image: "Blogs/blog-12.jpg",

//     content:
//       "Lately we have been reading stories about a number of organizations like Ikea, Microso..",
//     author: "Chinmayi",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jan 8, 2021",
//     status: "4 min read",
//     type: "blogs",
//   },

//   {
//     title: "Are EVs the way of the future?",
//     url: "are-evs-the-way-of-the-future",
//     image: "Blogs/blog-13.jpg",

//     content:
//       " As fuel costs continue to rise, more individuals are looking for alternate solu..",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Oct 28, 2021",
//     status: "4 min read",
//     type: "blogs",
//   },
//   {
//     title: "Debunking the Myths around Climate Change",
//     url: "debunking-the-myths",
//     image: "Blogs/blog14.jpg",

//     content:
//       "With Climate Change picking up steam, there has been a lot of confusion and..",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov 09 , 2021",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title: "Understanding the GreenHouse Gases-C02",
//     url: "understanding-the-greenhouse-gases",
//     image: "Blogs/blog15.jpg",

//     content:
//       "Our planet is becoming warmer. The heat is melting ice caps, changing..",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov 16, 2021",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title: "Lets talk about Carbon Taxes",
//     url: "let's-talk-about-carbon-taxes",
//     image: "Blogs/blog16.jpg",
//     content:
//       " The first warning of global warming came a century ago. With the discovery of the..",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov 23, 2021",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title: "Understanding the Greenhouse Gases- CH4",
//     url: "understanding-the-greenhouse-gases-ch4",
//     image: "Blogs/blog17.jpg",
//     content:
//       " Climate change conversations have focused too little attention on methane..",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec 07, 2021",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title: "Extreme weather events and how they are linked to Climate Change",
//     url: "extreme-weather-events-and-how-they-are-linked-to-climate-change",
//     image: "Blogs/b18.jpg",

//     content: "Climate disasters are part of the natural cycle of the weather..",
//     author: "Deeksha",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Dec 16, 2021",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title: "Shailesh Haribhakti joins Advisory Board of Updapt",
//     image: "Media/Shailesh_sir_image.svg",
//     content:
//       "Updapt is a new-age technology-driven company, providing comprehensive easy-to-use...",
//     url: "https://www.livemint.com/brand-post/shailesh-haribhakti-joins-advisory-board-of-updapt-a-digital-esg-platform-co-11642426951232.html",
//     websiteName: "livemint.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jan 17, 2022",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title: "February Newsletter 2022",
//     image: "Newsletter/feb-2022.svg",
//     content:
//       "Climate change poses the most significant long-term threat to the global economy.",
//     url: February2022,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Feb 01, 2022",
//     status: "4 min read",
//     type: "newsletter",
//   },
//   {
//     title: "March Newsletter 2022",
//     image: "Newsletter/mar-2022.svg",
//     content:
//       "The Institutional Investors Group on Climate Change (IIGCC) has published a new component for the Paris Aligned Investment Initiative (PAII) called Net Zero Investment Framework (NZIF).",
//     url: March2022,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Mar 01, 2022",
//     status: "4 min read",
//     type: "newsletter",
//   },
//   {
//     title: "Understanding Climate Justice",
//     url: "understanding-climate-justice",
//     image: "Blogs/b19.jpg",
//     content:
//       "In December 2019, Typhoon Ursula ravaged the Philippines, displacing thousands of people..",
//     author: "Deeksha",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Mar 08, 2022",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title: "April Newsletter 2022",
//     image: "Newsletter/april-2022.svg",
//     content:
//       "Under a new radical rule, the SEC has proposed for the first time that hundreds of businesses",
//     url: April2022,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "April 01, 2022",
//     status: "4 min read",
//     type: "newsletter",
//   },
//   {
//     title: "May Newsletter 2022",
//     image: "Newsletter/may 2022.svg",
//     content:
//       "Almost everything on earth is dependent on nature, yet most companies don’t count nature- related risks and opportunities in",
//     url: May2022,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 01, 2022",
//     status: "4 min read",
//     type: "newsletter",
//   },
//   {
//     title: "Accenture Sustainability Value Accelerator programme",
//     image: "Media/accenture_sust_val_updapt_logo_img.svg",
//     content:
//       "Accenture Sustainability Value Accelerator programme, which was launched in collaboration with...",
//     url: "https://yourstory.com/2022/05/accenture-sustainability-value-accelerator-programme/amp",
//     websiteName: "yourstory.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 17, 2022",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title: "CIIE.CO Cohort announcement",
//     image: "Media/cohort_announcement.svg",
//     content:
//       "We are proud to announce that 10 startups, who are furthering the #sustainability agenda, have...",
//     url: "https://www.linkedin.com/posts/ciieco_sustainability-accelerator-cohortlaunch-activity-6931945833001037824-0RGG?utm_source=linkedin_share&utm_medium=member_desktop_web",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 18, 2022",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title: "June Newsletter 2022",
//     image: "Newsletter/june-2022.svg",
//     content:
//       "Initiative Climat International (ICI), a global practitioner-led community of private equity firms and investors, is developing a new standard/guidance for accounting and reporting greenhouse gas emissions for the private equity sector.",
//     url: June2022,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 01, 2022",
//     status: "4 min read",
//     type: "newsletter",
//   },
//   {
//     title: "10 startups fighting against climate change",
//     image: "Media/plastic_bag.svg",
//     content:
//       "As we celebrate World Environment on 5 June, we need to take a look back at the innovative solutions brought in by startups...",
//     url: "https://www.printweek.in/news/10-startups-fighting-against-climate-change-56225",
//     websiteName: "printweek.in",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 03, 2022",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title: "ESG Summit 2022 organized by India CSR Network",
//     image: "Media/ESG_India.svg",
//     content:
//       "#ESG Summit 2022 was organized by India CSR Network with theme 'ESG for All, Sustainability First' on May 31, 2022 in...",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:6938427735908184064/",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 04, 2022",
//     status: "4 min read",
//     type: "media",
//   },
//   {
//     title: "Updapt - an ESG Tech Co. on LinkedIn: #Digital #Technology #Awards",
//     image: "Media/satish_ram.svg",
//     content:
//       "IMC #Digital #Technology #Awards ceremony was organized by IMC Chamber Of Commerce and Industry (IMC) on 22nd...",
//     url: "https://www.linkedin.com/posts/updapt-csr_digital-technology-awards-activity-6946415559387283456-k-3T/?utm_source=linkedin_share&utm_medium=member_desktop_web",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 28, 2022",
//     status: "4 min read",
//     type: "media",
//   },
//   {
//     title: "July Newsletter 2022",
//     image: "Newsletter/july-2022.svg",
//     content:
//       "53% of Small and Medium Enterprises (SMEs) investing in their sustainability",
//     url: July2022,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jul 10, 2022",
//     status: "6 min read",
//     type: "newsletter",
//   },
//   {
//     title: "August Newsletter 2022",
//     image: "Newsletter/aug-2022.svg",
//     content:
//       "Banks must sharpen their focus on climate risk - European Central Bank",
//     url: August2022,
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Aug 02, 2022",
//     status: "6 min read",
//     type: "newsletter",
//   },

//   {
//     title: "September Newsletter 2022",
//     image: "Newsletter/sep-2022.svg",
//     content:
//       "Inflation Reduction Act - the most significant investment turned into law in the US",
//     url: September2022,

//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Sep 01, 2022",
//     status: "6 min read",
//     type: "newsletter",
//   },

//   {
//     title: "Let’s talk about Biofuels",
//     url: "let's-talk-about-biofuels",
//     image: "Blogs/b20.jpg",
//     content:
//       "Using biofuels for sustainability has been around since the 1800s.",
//     author: "Meenakshi",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Sep 07, 2022",
//     status: "2 min read",
//     type: "blogs",
//   },

//   {
//     title: "Where does the world stand with SDG 2030 today?",
//     url: "where-does-the-world-stand-with-sdg-2030-today",
//     image: "Blogs/giftboxes.jpg",
//     content:
//       "The United Nations adopted 17 Sustainable Development Goals in 2015 as an action to eradicate poverty, sustain the earth, and secure the goal of peace and prosperity by the year 2030.",
//     author: "Alice Patricia",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Sep 15, 2022",
//     status: "2 min read",
//     type: "blogs",
//   },

//   {
//     title:
//       "Updapt - new partnership announcement with DNV Supply Chain and Product Assurance (SCPA)",
//     image: "Media/dnv_updapt.jpg",
//     content:
//       "Updapt is glad to announce a partnership with DNV Supply Chain and Product Assurance (SCPA), one of...",
//     url: "dnv-scpa-collaborates-with-updapt-an-esg-tech-co-to-transform-your-sustainability-journey-230553",
//     websiteName: "updaptcsr.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Sept 23, 2022",
//     status: "2 min read",
//     type: "newsroom-media",
//   },
//   {
//     title: "October Newsletter 2022",
//     title1: "October 2022",
//     image: "Newsletter/newsletter1.jpg",
//     content:
//       "Like many other countries, UAE aims6 to reach net-zero carbon emissions or toward a green economy...",
//     url: "/resource/newsletter/october2022Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Oct 01, 2022",
//     status: "4 min read",
//     sameTab: true,
//     type: "newsletter",
//   },
//   {
//     title:
//       "Mainstreaming sustainability: how Indian startups are warming up to ESG issues",
//     image: "images/Oct-Blog-image.jpg",
//     content:
//       "Given the agriculture and climate-related issues in India, there is a huge opportunity for ESG ventures to deliver high internal rate of returns that can attract VCs",
//     url: "https://economictimes.indiatimes.com/prime/corporate-governance/mainstreaming-sustainability-how-indian-startups-are-warming-up-to-esg-issues/primearticleshow/94769904.cms",
//     websiteName: "economictimes.indiatimes.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Oct 12, 2022",
//     status: "4 min read",
//     type: "media",
//   },

//   {
//     title: "Importance of an ESG Report for a Better ESG Management",
//     url: "importance-of-an-esg-report-for-a-better-esg-management",
//     image: "Blogs/esg-img.jpg",
//     content:
//       "The investment community is becoming increasingly aware of ESG risks and opportunities.",
//     author: "Veenapani Joshi",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Oct 17, 2022",
//     status: "2 min read",
//     type: "blogs",
//   },

//   {
//     title: "Updapt - an ESG Tech Co. was part of ASSOCHAM",
//     image: "Media/esg-conclave.jpg",
//     content:
//       "Updapt - an ESG Tech Co. was part of ASSOCHAM (The Associated Chambers of Commerce and Industry of India) ESG Conclave 2022 held on 23rd September 2022 with theme ‘Challenges, Opportunities & The Way Forward’.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:6980192749761277952",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Oct 19, 2022",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Building a Sustainable Tomorrow, Transforming Risks into Opportunities",
//     image: "Media/summit.jpg",
//     content:
//       "Themed 'Building a Sustainable Tomorrow, Transforming Risks into Opportunities', the 17th Sustainability Summit 2022 organized...",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:6982685107232886784",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Oct 19, 2022",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Updapt - an ESG Tech Co. to run the #Sustainability #Awareness webinar for RSA Global",
//     image: "Media/rsa_global.jpg",
//     content:
//       "It was a pleasure for Updapt - an ESG Tech Co. to run the #Sustainability #Awareness webinar for RSA Global, a global logistics and supply chain solutions company headquartered in UAE.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:6985544000778719233",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Oct 19, 2022",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title: "November Newsletter 2022",
//     title1: "November 2022",
//     image: "Newsletter/newsletter2.jpg",
//     content:
//       "The Association of Banks in Singapore (ABS) has launched new sustainable private banking...",
//     url: "/resource/newsletter/november2022Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov 01, 2022",
//     status: "4 min read",
//     sameTab: true,
//     type: "newsletter",
//   },
//   {
//     title:
//       "As NTPC improves its ESG score, why it may be too early to celebrate. (Hint: think coal)",
//     image: "Media/coal_work.jpg",
//     content:
//       "As per Morningstar Sustainalytics July ratings, the power producer's ESG score has improved from ‘severe to ‘high-risk.",
//     url: "https://economictimes.indiatimes.com/prime/energy/as-ntpc-betters-its-esg-score-why-it-is-t[…]elebrate-hint-think-coal/primearticleshow/95129284.cms",
//     websiteName: "economictimes.indiatimes.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov 03, 2022",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title: "How can we achieve decarbonization?",
//     url: "how-can-we-achieve-decarbonization",
//     image: "Blogs/Rectangle23.jpg",
//     content:
//       "Decarbonization is critical in limiting global warming and for companies declaring carbon neutral or net zero by 2050. ",
//     author: "Meenakshi",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Nov 09, 2022",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title: "Technology Can Help Businesses Make ESG Reporting Easy",
//     image: "Media/satishmedia.jpg",
//     content:
//       "Currently, ESG data management and disclosure is one of the key challenges faced by companies in India including over 80% of the 1,000 listed companies along with decarbonization execution and capacity building.",
//     url: "https://www.outlookindia.com/business/technology-can-help-businesses-make-esg-reporting-easy-news-239058",
//     websiteName: "outlookindia.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov 22, 2022",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title:
//       "IFRS announces CDP's incorporation of the ISSB Climate-related Disclosures.  ",
//     title1: "December 2022",
//     image: "Newsletter/last.jpg",
//     content:
//       "An announcement was made by the IFRS to the global market ahead of finance day at COP27 about CDP's...",
//     url: "/resource/newsletter/december2022Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Dec 01, 2022",
//     status: "4 min read",
//     sameTab: true,
//     type: "newsletter",
//   },
//   {
//     title: "Benefits of Impact Investment",
//     url: "benefits-of-impact-investment",
//     image: "Blogs/blog24.jpg",
//     content:
//       "Impact investment market supplies capital to address the world's most crucial challenges in sustainable farming, conventional energy, conservation of resources, and microfinance, along with inexpensive and available essential services.",
//     author: "Meenakshi",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Dec 19, 2022",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title: "Introduction and Recap COP27",
//     image: "images/podcast-theme.jpg",
//     url: "introduction-and-recap-cop27",
//     date: "Jan 01, 2023",
//     duration: "10 min",
//     status: "10 min",
//     type: "podcast",
//     host: "Meenakshi Venkatachalapathy",
//     content:
//       "Our focused podcast series concentrates on hot topics and trending ESG industry news.",
//     synopsis:
//       " COP27, the 27th session of the conference of the parties to the United Nations Framework Convention on climate change, is the topic for our podcast episode for environment and sustainability enthusiasts. Join us as our speaker, Meenakshi Venkatachalapathy, Sustainability consultant at Updapt, give us a quick recap of the #CO27 event and discuss trends in growth and decision-making. The podcast divulges Updapt's perspectives on the changing climate and our focus on the continuous pursuit of sustainability and economic and social responsibility.",
//     podcast_audio: "podcast1.mp3",
//   },

//   {
//     title:
//       "Indian Parliament passes an Energy Conservation bill to promote non-fossil energy sources.",
//     title1: "January 2023",
//     image: "images/jan1.jpg",
//     content:
//       "The parliament of India have passed the energy conservation bill in both houses ( Lokha Sabha and Rajya Sabha)...",
//     // url: "/resource/newsletter?month=january&year=2023",
//     url: "/resource/newsletter/january2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jan 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "Satish Ramchandani from Updapt: Software to monitor and cut carbon emissions",
//     image: "images/podcast-2.jpg",
//     url: "software-to-monitor-and-cut-carbon-emissions",
//     date: "Jan 10, 2023",
//     duration: "28 min",
//     status: "28 min",
//     type: "podcast",
//     host: "Bharti Chhatre Krishnan",
//     synopsis:
//       "CSR and ESG were once used interchangeably, and mostly referred to programmes for underserved communities (for example- water coolers in village schools). This information was typically kept in excel spreadsheets and was reviewed near reporting deadlines. However, net zero pledges are changing ESG reporting. Organizations must now continuously measure their carbon footprints because regulators, investors, suppliers, as well as millennial customers, want to know their green credentials. It's no surprise that ESG tech is a growing opportunity, with investors across the world queuing up to invest in ESG tech companies.",
//     podcast_audio: "podcast2.mp3",
//   },
//   {
//     title:
//       "The Federal Reserve Board (FRB) begins the pilot climate scenario analysis exercise",
//     title1: "February 2023",
//     image: "images/feb2023-1.jpg",
//     content:
//       "The Federal Reserve Board is conducting a climate scenario analysis exercise and has released an instruction document to understand...",
//     // url: "/resource/newsletter?month=february&year=2023",

//     url: "/resource/newsletter/february2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Feb 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "Mr. Erik Solheim joins Advisory Board of Updapt (an ESG Tech Co.)",
//     image: "Media/erik-solheim.jpg",
//     content:
//       "Updapt, a SaaS based ESG Solution Co., announced today that Mr. Erik Solheim, a Green Leader and Norwegian Diplomat, has joined their Advisory Board to mentor the leadership team in their journey of solving core environment issues by building sustainability led digital solutions for businesses in global markets including European Union (EU) region.",
//     url: "https://londondailypost.com/mr-erik-solheim-joins-advisory-board-of-updapt-an-esg-tech-co/",
//     websiteName: "londondailypost.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Jan 24, 2023",
//     status: "3 min read",
//     type: "newsroom",
//   },
//   {
//     title: "Internal Carbon Pricing",
//     url: "internal-carbon-pricing",
//     image: "Blogs/blog25.jpg",
//     content:
//       "Carbon risk and its effects are fast approaching, as seen in January 2021, when the Bank of England alerted businesses about the surge in carbon price to $100 per ton.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Feb 20, 2023",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title: "Recycling and Carbon Emissions Conference (RACE Conferences)",
//     image: "Media/race-conferences.jpg",
//     content:
//       "The recently concluded Recycling and Carbon Emissions Conference (RACE Conferences) on 4th February 2023 saw active participation from various businesses, agencies and experts in field of #wastemanagement, #plasticrecycling and #sustainability sharing insights on related regulations, challenges, technology and methods on how to reduce #emissions with recycling as a central component.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7031880387979096064",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Feb 17, 2023",
//     status: "3 min read",
//     type: "media",
//   },
//   {
//     title:
//       "SEBI, capital market regulator in India has released a new set of proposals seeking public consultation in ESG disclosure, ESG ratings and ESG investing.",
//     title1: "March 2023",
//     image: "images/newsletter-2023.png",
//     content:
//       "Following the Business Responsibility and Sustainability Reporting (BRSR), the Securities and Exchange Board of India (SEBI) has mandated the disclosure of ESG data by the top 1000 listed companies (based on market capitalization).",
//     url: "/resource/newsletter/march2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "March 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "The World ESG Summit by Gulf Xellence",
//     image: "Media/world-esg-summit.jpg",
//     content:
//       "The World ESG Summit by Gulf Xellence was conducted from 21 - 23 February 2023 in #Dubai bringing in thought leaders, corporations, government from #UAE, #SaudiArabia and other #GCC countries.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7036374438409318400",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Feb 28, 2023",
//     status: "3 min read",
//     type: "media",
//   },
//   {
//     title: "ESG Leadership Summit 2023",
//     image: "Media/esg-leadership-summit-2023.jpg",
//     content:
//       "Dun & Bradstreet India hosted the inaugural edition of 'ESG Leadership Summit 2023' on the theme 'Building A Sustainable Future' on March 15, 2023.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7044545011912163328",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "March 23, 2023",
//     status: "3 min read",
//     type: "media",
//   },
//   {
//     title: 'Different terms, but the goal is "Let\'s go Net Zero!"',
//     url: "different-terms-but-the-goal-is-lets-go-net-zero",
//     image: "Blogs/blog26.jpg",
//     content:
//       "Net Zero is a global objective that must be driven at the national level, where its policies are followed locally by all countries, cities and businesses developing an action plan.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Mar 28, 2023",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title:
//       "UAE is the first Gulf country to commit to net-zero emissions by 2050",
//     title1: "April 2023",
//     image: "images/apr2023-1.jpg",
//     content:
//       "Ahead of hosting COP28 later this year, The UAE government signed a Net Zero by 2050 charter, launched initially as a net zero 2050 strategic initiative at Expo 2020 in Dubai, followed by an announcement with the UN secretary general and at the COP27.",
//     url: "/resource/newsletter/april2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "April 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "Climate Plans Lost In Data Matrix",
//     image: "Media/climate-plans-lost-in-data-matrix.jpg",
//     content:
//       "Data gaps are throwing a spanner in the works for a world which is falling distressingly short of commitments to slow down, if not reverse, rapidly deteriorating global climate conditions and India is not an outlier.",
//     url: "https://www.outlookbusiness.com/the-big-story-1/lead-story-8/climate-plans-lost-in-data-matrix-6741",
//     websiteName: "outlookbusiness.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "April 01, 2023",
//     status: "9 min read",
//     type: "newsroom",
//   },
//   {
//     title: "Hong Kong Exchange Requires Climate Disclosures from 2024.",
//     title1: "May 2023",
//     image: "images/may2023-1.jpg",
//     content:
//       "Hong Kong's Stock Exchange is set to require all listed companies to provide climate-related disclosures in line with the International Sustainability Standards Board's (ISSB) upcoming Climate Standard.",
//     url: "/resource/newsletter/may2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "Evolving Sustainability Practices and Global Trends: Insights from Capt. Tapas Majumdar",
//     image: "Media/evolving-sustainability-practices-and-global-trends.jpg",
//     content:
//       "Capt. Tapas Majumdar, Founder Director @ The Sustainability Practitioners, Independent Director, Sustainability (ESG) Reporting & Assurance, Member Board of Studies (BOS) in discussion with Pratik Patankar, Associate Director at Updapt - an ESG Tech Co on evolving sustainability practices and global trends across economies.",
//     url: "https://www.youtube.com/watch?v=4syxstY7wTA",
//     websiteName: "youtube.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 18, 2023",
//     status: "24 min read",
//     type: "media",
//   },
//   {
//     title:
//       "The Australian government establishes the Net Zero Authority to oversee the smooth transition to a low-carbon economy.",
//     title1: "June 2023",
//     image: "images/june2023-1.jpg",
//     content:
//       "The Australian government's Net Zero Authority will guide the country's economic transformation towards achieving net-zero emissions, reskilling workers for the energy transition, and facilitating collaboration with industry and investors on transformation opportunities.",
//     url: "/resource/newsletter/june2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "NASSCOM GreenTables | Green Transition With Tech",
//     image: "Media/nasscom-green-tables.png",
//     content:
//       "Join us for this insightful discussion on 'Green Transition with Tech' by Santhosh Jayaram (Global Head - Sustainability, HCL Tech), Apurba Mitra (Associate Partner, KPMG India) & Anuj Duggal (Head - Sustainability & ESG, nasscom).",
//     url: "https://www.linkedin.com/events/nasscomgreentables-greentransit7069649274807947264/comments/",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 08, 2023",
//     status: "34 min",
//     type: "media",
//   },
//   {
//     title:
//       "The Importance of Integrating DEI (Diversity, Equity, and Inclusion Practices) in the Workplace in achieving sustainable development goals",
//     url: "the-importance-of-integrating-dei",
//     image: "Blogs/blog27.jpg",
//     content:
//       "Integrating DEI practices in the workplace can significantly contribute to achieving sustainable development goals and create a more inclusive and equitable environment, leading to a more engaged and productive workforce where people from diverse backgrounds are empowered to bring their unique perspectives and skills.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Jun 27, 2023",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title: "UK and France Launch Joint Biodiversity Credits Initiative.",
//     title1: "July 2023",
//     image: "images/july2023-1.jpg",
//     content:
//       "The United Kingdom and France have joined forces to launch a biodiversity credits initiative to support companies in their efforts to contribute to nature recovery.",
//     url: "/resource/newsletter/july2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "July 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "Updapt - an ESG Tech Co., participated in #TiEcon2023, organized by TiE Mumbai.",
//     image: "Media/tie-mumbai.jpg",
//     content:
//       "Updapt's participation in #TiEcon2023 showcased its commitment to #climatetech #innovation, engaging with industry leaders and investors, and exploring avenues for #growth and #collaboration.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7071849589049335808",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 08, 2023",
//     status: "4 min read",
//     type: "media",
//   },
//   {
//     title: "How ESG is slowly making Indian businesses future-ready",
//     image: "Media/how-esg-is--slowly-making-indian-businesses-future-ready.jpg",
//     content:
//       "From building a strong reputation to managing risks, navigating regulatory changes to attracting capital, and identifying innovation and market opportunities, embracing ESG can help businesses become future-ready",
//     url: "https://www.businesstoday.in/interactive/longread/the-esg-imperative-indian-businesses-future-ready-265-20-06-2023",
//     websiteName: "businesstoday.in",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 20, 2023",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title: "The Tech Push to ESG Data Management",
//     image: "Media/the-tech-push-to-esg-data-management.jpg",
//     content:
//       "Data management platforms are helping buisnesses make informed decisions to optimise their environmental, social and governance regime by collecting and standardising data.",
//     url: "https://www.outlookbusiness.com/the-big-story-1/lead-story-8/the-tech-push-to-esg-data-management-6789",
//     websiteName: "outlookbusiness.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "July 01, 2023",
//     status: "3 min read",
//     type: "newsroom",
//   },
//   {
//     title: "London Climate Action Week",
//     image: "Media/london-climate-action-week.jpg",
//     content:
//       "#Updapt UK Limited was part of London Climate Action Week during 25th June to 2nd July 2023 and had exhibited in Reset Connect ExCeL London showcasing its innovative SaaS-based ESG solutions, empowering businesses to digitize and simplify their sustainability journey towards their #netzero goals.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7082969775340945409/",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "July 07, 2023",
//     status: "5 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Singapore Proposes Mandatory Climate Reporting for Public and Private Companies, Aligned with International Standards.",
//     title1: "August 2023",
//     image: "images/august2023-1.jpg",
//     content:
//       "Singapore's Accounting and Corporate Regulatory Authority (ACRA) and Singapore Exchange Regulation (SGX RegCo) have proposed a new requirement for public and large private companies in the country to provide climate-related disclosures.",
//     url: "/resource/newsletter/august2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "August 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "UpdaptESG All-in-One Sustainability Software",
//     url: "updapt-esg-all-in-one-sustainability-software",
//     image: "Blogs/updapt-esg-all-in-one.png",
//     content:
//       "In today's world, sustainability is a crucial aspect of any business. Organizations worldwide are striving to improve their Environmental, Social, and Governance (ESG) performance to meet the demands of a rapidly changing landscape.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Aug 22, 2023",
//     status: "2 min read",
//     type: "blogs",
//   },
//   {
//     title:
//       "EU Adopts Rules Requiring Product Emissions Reporting for New Import Carbon Tax.",
//     title1: "September 2023",
//     image: "images/sep2023-11.jpg",
//     content:
//       "The European Commission has introduced reporting rules for importers under the Carbon Border Adjustment Mechanism (CBAM), the EU's new carbon tax on imported goods, aiming to align carbon prices paid by European and non-EU producers.",
//     url: "/resource/newsletter/september2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "September 07, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "Climate Litigation Risk and Best ESG Practices",
//     image: "Media/climate-litigation-risk.jpg",
//     content:
//       "Bose Varghese, Senior Director - ESG, at Cyril Amarchand Mangaldas in discussion with  Pratik Patankar, Director at Updapt - an ESG Tech Co. on landscape of climate litigation risk and its efficient management through technology.",
//     url: "https://www.youtube.com/watch?v=yVTTSjsMz60",
//     websiteName: "youtube.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Sep 12, 2023",
//     status: "26 min read",
//     type: "media",
//   },
//   {
//     title:
//       " California Legislators Approve Bill Mandating Full Value Chain Emissions Disclosure by Companies.",
//     title1: "October 2023",
//     image: "images/oct2023-1.jpeg",
//     content:
//       " A new proposed California law that could require most large U.S. companies to disclose their full value chain greenhouse gas (GHG) emissions passed the state's Assembly.",
//     url: "/resource/newsletter/october2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "Cement Industry Case Study",
//     image: "images/cementIndustry.jpg",
//     content:
//       "The company is one of the India's pioneering homegrown cement companies. The company operates a manufacturing capacity of 35.9 MnT per annum (MTPA), across 14 cement plants and grinding units which are spread across 10 states.",
//     url: "/resource/case-study/esg-reporting-for-cement-companies",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 05, 2023",
//     status: "4 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title: "Healthcare case study",
//     image: "images/healthCare.jpg",
//     content:
//       "Client is multinational healthcare group. Apart from the eponymous hospital chain, the company also operates pharmacies, primary care, diagnostic centres, telehealth clinics, and digital healthcare services through its subsidiaries. With an annual revenue of US$1.8 billion and an employee strength of over 60,000, Client Hospitals desired to pioneer       sustainability and blend better ESG practices as part of its operations.",
//     url: "/resource/case-study/esg-reporting-for-healthcare-sector",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 07, 2023",
//     status: "4 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title: "Pharma case study",

//     image: "images/pharmaCase.jpg",
//     content:
//       "Client is India's one of the largest pharma company with a revenue of over USD 2 billion dollars, present in more than 80 countries and with over 47 manufacturing facilities across 6 countries. The organisation has a sustainability commitment to achieve Carbon and Water neutrality by the year 2025. Responsible resource consumption and sustainable operations form part of the organisation's vision and values.",
//     url: "/resource/case-study/esg-reporting-for-pharma-companies",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 08, 2023",
//     status: "5 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title: "Power & Renewables case study",

//     image: "images/powerAndRenewables.jpg",
//     content:
//       "The company is the countries' largest integrated power company with an installed generation capacity of 14,110 MW. The organisation has a diversified line of business venturing into renewable energy generation, conventional energy generation, transmission and distribution including several others. With over 12.5 Mn distribution consumers and a legacy of 100+ years in the power generation business, the organisation along with its subsidiaries and joint entities is present across the entire power value chain. The organisation with a 30% clean energy portfolio has committed to being Carbon 'Net Zero' before 2045. It has also committed to Water neutrality before 2030 and ensuring Zero waste to landfills before 2030",
//     url: "/resource/case-study/esg-reporting-for-energy-sector",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 10, 2023",
//     status: "3 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title: "Realty case study",

//     image: "images/realtyCase.jpg",
//     content:
//       "Client is one of India's finest real estate developers that has offered more than 50,000 homes in the last five to seven years. The company develops residential real estate, focusing on affordability while offering premium and luxury housing, office spaces, retail spaces, and digital infrastructure properties in Mumbai, Thane, Pune, Bangalore & London. ",
//     url: "/resource/case-study/esg-reporting-for-real-estate-sector",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 15, 2023",
//     status: "4 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title: "Supplier assessment case study",

//     image: "images/supplierAssessmentCase.jpg",
//     content:
//       "One of our pharmaceutical partners have used our supplier assessment module to create a new “Code of conduct” survey for their 5000+ suppliers in their value chain. They created a custom survey consisting of 52 questions. The survey was qualitative in nature and wanted to assess the suppliers on the following topics",
//     url: "/resource/case-study/esg-reporting-for-supplier-assessment",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 16, 2023",
//     status: "3 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title: "Waste management industry case study",

//     image: "images/wasteManagementCase.jpg",
//     content:
//       "The client is a large waste management company and manages 7 million tons of waste every year and is also the first to establish a waste management facility specifically for biomedical, Industrial, and integrated municipal in the country.",

//     url: "/resource/case-study/esg-reporting-for-waste-management-companies",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 19, 2023",
//     status: "5 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title:
//       "Australia has unveiled the proposed climate-related reporting standards based on the IFRS framework.",
//     title1: "November 2023",
//     image: "images/nov2023-1.jpg",
//     content:
//       "Australia has unveiled proposed climate-related reporting standards, building on the International Sustainability Standards Board (ISSB) of the IFRS Foundation's sustainability disclosure standards.",
//     url: "/resource/newsletter/november2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "November 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "GITEX GLOBAL Largest Tech & Startup Show in the World, Dubai World Trade Centre.",
//     image: "Media/gitex-impact.jpg",
//     content:
//       "Updapt - an ESG Tech Co. is showcasing its cutting-edge Climate Tech solutions at GITEX IMPACT, GITEX GLOBAL Largest Tech & Startup Show in the World, Dubai World Trade Centre.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7119751006472581122",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 18, 2023",
//     status: "5 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Updapt - an ESG Tech Co. was the ESG Tech Partner at 2nd Edition Sustainability Summit & Awards 2023.",
//     image: "Media/sustainability-summit-and-awards-2023.jpg",
//     content:
//       "Updapt - an ESG Tech Co. was the ESG Tech Partner at 2d Edition Sustainability Summit & Awards 2023. Organised by UBS Forums Pvt. Ltd. at Taj MG Road, Bengaluru on 28th of Sept 2023 showcasing its innovative SaaS-Based ESG solution, empowering businesses to digitize and simplify their sustainability journey towards their netzero goals.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7113423863216037888",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 23, 2023",
//     status: "4 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Ms. Maryam Telmesani, Saudi Arabia, joins Advisory Board of Updapt (an ESG Tech Co.)",
//     image: "Media/maryam-telmesani-media.jpg",
//     content:
//       "Karnataka, India, 24th October 2023, ZEX PR WIRE, Updapt, a SaaS based ESG Solution Co., announced today that Ms. Maryam Telmesani has joined their Advisory Board to provide strategic guidance for solving key sustainability issues of the market it addresses and expansion in Middle East region.",
//     url: "https://americantribune.co/ms-maryam-telmesani-saudi-arabia-joins-advisory-board-of-updapt-an-esg-tech-co/",
//     websiteName: "americantribune.co",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 24, 2023",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title:
//       "Updapt- An ESG Tech Co. Sustainability Spotlight Series - Mr. Ramveer Tanwar",
//     image: "Media/spotlight-series-mr-ramveer-tanwar.jpg",
//     content:
//       "In a world where 2 billion people, constituting 26% of the global population, lack access to safe drinking water, and an alarming 3.6 billion individuals, or 46%, face the absence of safely managed sanitation, our shared responsibility for water conservation has never been more critical. ",
//     url: "https://www.youtube.com/watch?v=vWQFel1mvV8",
//     websiteName: "youtube.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "Nov 9, 2023",
//     status: "22 min read",
//     type: "media",
//   },
//   {
//     title: "Details About ESG Data Management Software For 2024",
//     url: "details-about-esg-data-management-software-for-2024",
//     image: "Blogs/blog-29-image-1.jpg",
//     content:
//       "Updapt ESG Data Management Software helps all types of entities to manage their ESG Data Effectively and at the same time it gathers all emission data too.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Nov 17, 2023",
//     status: "5 min read",
//     type: "blogs",
//   },
//   {
//     title:
//       "Dutch Regulator AFM Urges Comprehensive Sustainability Impact Disclosures Across All Financial Products.",
//     title1: "December 2023",
//     image: "images/dec2023-1.jpg",
//     content:
//       "The Dutch Authority for the Financial Markets (AFM) has urged comprehensive sustainability impact disclosures for all financial products, regardless of their sustainability features.",
//     url: "/resource/newsletter/december2023Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "December 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "How Sebi's stringent sustainability reporting mandate is proving to be a challenge for top listed companies.",
//     image: "Media/sebi-stringent-sustainability-reporting.png",
//     content:
//       "In 2021, the Securities and Exchange Board of India (Sebi) took a big leap in promoting sustainable practices in companies by introducing a framework for reporting such actions. These incorporated the environmental, social and governance (ESG) impacts of businesses in a bid to future-proof them.",
//     url: "https://www.businesstoday.in/magazine/deep-dive/story/how-sebis-stringent-sustainability-reporting-mandate-is-proving-to-be-a-challenge-for-top-listed-companies-407240-2023-11-28#amp_tf=From%20%251%24s&aoh=17011932332893&referrer=https%3A%2F%2Fwww.google.com&ampshare=https%3A%2F%2Fwww.businesstoday.in%2Fmagazine%2Fdeep-dive%2Fstory%2Fhow-sebis-stringent-sustainability-reporting-mandate-is-proving-to-be-a-challenge-for-top-listed-companies-407240-2023-11-28",
//     websiteName: "businesstoday.in",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "December 10, 2023",
//     status: "4 min read",
//     type: "newsroom",
//   },
//   {
//     title:
//       "Updapt - an ESG Tech Co.. was part of India Responsible Capital Conference (IRCC) 2023 at IIMA.",
//     image: "Media/india-responsible-capital-conference-IRCC-2023.jpg",
//     content:
//       "Updapt - an ESG Tech Co.. was part of India Responsible Capital Conference (IRCC) 2023 at IIMA. IRCC-2023 was organized by the Arun Duggal Centre for ESG Research (CESGR) @ IIMA and the Misra Centre for Financial Markets and Economy at IIMA (MCFME), Indian Institute of Management Ahmedabad.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7143854552725848068",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "December 25, 2023",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title:
//       "COP28 UAE, the 28th session of the UN Climate Change Conference of Parties,",
//     image: "Media/cop28-uae.jpg",
//     content:
//       "COP28 UAE, the 28th session of the UN Climate Change Conference of Parties, is currently underway in the Expo City Dubai #UAE, drawing delegates from nearly 200 nations for a pivotal gathering aimed at formulating effective strategies against global warming.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7138788111429386240",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "December 25, 2023",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title:
//       "ACCC Releases Eight Principles for Clear and Accurate Environmental Marketing Claims.",
//     title1: "January 2024",
//     image: "images/jan2024-1.jpg",
//     content:
//       "The Australian Competition and Consumer Commission (ACCC) has unveiled a set of eight principles designed to assist businesses in ensuring the transparency and accuracy of environmental marketing and advertising claims.",
//     url: "/resource/newsletter/january2024Newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "January 01, 2023",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "Updapt's Satish Ramchandani on opportunities for the company's ESG software product",
//     image: "Media/updapt's-satish-ramchandani.jpg",
//     content:
//       "Satish Ramchandani, co-founder and chief business officer of Updapt, gives us a quick update about the company which offers software solutions for businesses to manage their ESG, meaning environment, sustainability and governance, reporting.",
//     url: "https://www.forbesindia.com/audio/forbes-india-tech-conver-sations/updapts-satish-ramchandani-on-opportunities-for-the-companys-esg-software-product/90797",
//     websiteName: "forbesindia.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "January 11, 2024",
//     status: "32 min read",
//     type: "media",
//   },
//   {
//     title: "Fast Moving Consumer Goods case study",
//     image: "images/fmcgCase.jpg",
//     content:
//       "Client is a Singapore based conglomerate that has completed 75 years of successful operations, spanning over Asia, Africa and Europe, employing more than 20,000 people, across 10 plus countries, and reaching over one billion customers.",
//     url: "/resource/case-study/esg-reporting-for-fmcg-sector",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "January 31, 2024",
//     status: "4 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title:
//       "EBA Proposes Guidelines for Banks on ESG and Climate Transition Risk Management.",
//     title1: "February 2024",
//     image: "images/feb2024-1.jpg",
//     content:
//       "The European Banking Authority (EBA) has introduced proposed guidelines to empower banks in managing environmental, social, and governance (ESG) risks amid the transition to a climate-neutral economy.",
//     url: "/resource/newsletter/february2024newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "February 01, 2024",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "Transition Pathway Initiative (TPI): Streamlining the Low-Carbon Journey for businesses.",
//     url: "transition-pathway-initiative",
//     image: "Blogs/blog-30-thumbnail.png",
//     content:
//       "In the sphere of sustainability and corporate accountability, the Transition Pathway Initiative (TPI) emerges as a consequential force, providing invaluable guidance to businesses as they navigate the complexities of transitioning to the circular economy.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Feb 20, 2024",
//     status: "3 min read",
//     type: "blogs",
//   },
//   {
//     title:
//       "Updapt - an ESG Tech Co. Tech Co was invited to Leadership Summit 2024, organized by the SDA Bocconi Asia Center.",
//     image: "Media/leadership-summit-2024.jpg",
//     content:
//       "As the demand for #sustainable business practices continues to grow, business schools are increasingly incorporating #sustainability into their curriculum including forming research cells on #ESG and inviting experts to share diverse perspectives with students on managing #climaterisks and related opportunities.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7161599626465210368",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "February 25, 2024",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title: "CFO Leadership Summit in Riyadh, Saudi Arabia.",
//     image: "Media/cfo-leadership-summi-in-riyadh.jpg",
//     content:
//       "In rapidly evolving corporate landscape, companies worldwide are placing increasing importance on #ESG factors. Among key decision-makers, the Chief Financial Officer (CFO) plays a crucial role in guiding the organization's efforts toward #sustainability.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7165339795198468097",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "February 25, 2024",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Kunal Raman, Director at Updapt - an ESG Tech Co., spoke about Sustainable Practices in the Mining Industry at the Odisha Mining and Infrastructure International Expo 2024 ",
//     image: "Media/odisha-mining-and-infrastructure-international-expo-2024.jpg",
//     content:
//       "The #miningindustry is a critical contributor to the global economy. It provides necessary resources for various sectors, generates #employment, and boosts economies, contributing around 3-4% of the world's #GDP.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7166039668193538048",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "February 26, 2024",
//     status: "3 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Japan Introduces Climate Transition Bond to Support Sustainability Goals.",
//     title1: "March 2024",
//     image: "images/mar2024-1.jpg",
//     content:
//       "The Japanese government recently announced the successful issuance of its inaugural Climate Transition Bond, raising JPY800 billion (USD$5.3 billion).",
//     url: "/resource/newsletter/march2024newsletter",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "March 01, 2024",
//     status: "3 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "Updapt ESG Information Tech. LLC, in the #MiddleEast, ESG Summit 2024, Saudi Arabia.",
//     image: "Media/esg-summit-updapt-llc-2024.jpg",
//     content:
//       "We are ecstatic to share that Updapt - an ESG Tech Co., has been honored with the Best #ESG Reporting, Management & Analytics tool Award at the ESG Summit 2024 in Saudi Arabia! ",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7168597015914921985",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "March 5, 2024",
//     status: "3 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Updapt - an ESG Tech Co. participated as ESG Tech Partner at ESG Summit 2024, Saudi Arabia.",
//     image: "Media/esg-summit-2024.jpg",
//     content:
//       "The cornerstone of #Saudi Arabia's approach to sustainability is encapsulated in Vision 2030, a strategic blueprint aimed at diminishing the country's reliance on oil by fostering the growth of public service sectors and nurturing a diverse, #sustainable economy. Integral to this vision is the Saudi Green Initiative, which sets forth ambitious environmental conservation and sustainable development targets.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7171066996879421441",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "March 12, 2024",
//     status: "3 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Harnessing Technology for ESG: How SaaS Solutions Drive Sustainable Business Practices",
//     image: "Media/harnessing-technology-for-esg.jpg",
//     content:
//       "The call for environmental, social, and governance (ESG) compliance in business reflects the growing awareness of the need for responsible and ethical economic growth.",
//     url: "https://timestech.in/harnessing-technology-for-esg-how-saas-solutions-drive-sustainable-business-practices/",
//     websiteName: "timestech.in",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "March 19, 2024",
//     status: "3 min read",
//     type: "media",
//   },
//   {
//     title:
//       "In a compelling live Television discussion on Mirror Now by Times Network The Urban Debate, Ajay kumar Anand, Director at Updapt - an ESG Tech Co.",
//     image: "Media/water-crisis-mirror-now.jpg",
//     content:
//       "#Water, our most precious resource, sustains life, powers industries, and is indispensable for healthy ecosystems. Shockingly, nearly 2.2 billion people around the globe are deprived of access to safely managed drinking water. Despite ongoing efforts to enhance our water supply systems, a significant portion of urban cities in many countries continue to grapple with acute water stress including its quality. ",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7174429516549787650",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "March 19, 2024",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title: "National Green Infrastructure Conclave 2024",
//     image: "Media/national-green-infrastructure-conclave-2024.jpg",
//     content:
//       "#Green #infrastructure plays a crucial role in #ecological preservation, contributing to both #social and #environmental #sustainability. Emphasized by the New Urban Agenda and the @Sustainable Development Goals (#SDGs), to build resilient infrastructure, promote #sustainable industrialization, and foster innovation.",
//     url: "https://www.linkedin.com/feed/update/urn:li:activity:7175816323300528131",
//     websiteName: "linkedin.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "March 20, 2024",
//     status: "2 min read",
//     type: "media",
//   },
//   {
//     title:
//       "Choose Right Sustainability and ESG Software for Small Businesses in 2024",
//     url: "esg-software-for-small-businesses-in-2024-updapt",
//     image: "Blogs/blog-31-image-1.jpg",
//     content:
//       "As sustainability becomes a core component of business strategy, small businesses increasingly recognize the need to integrate Environmental, Social, and Governance (ESG) practices into their operations.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Mar 21, 2024",
//     status: "4 min read",
//     type: "blogs",
//   },
//   {
//     title:
//       "The Securities and Exchange Commission Enhances Climate-Related Disclosures for Companies.",
//     title1: "April 2024",
//     image: "images/apr2024-1.jpg",
//     content:
//       "The Securities and Exchange Commission (SEC) has adopted amendments to its rules under the Securities Act of 1933 and the Securities Exchange Act of 1934, mandating companies to furnish detailed climate-related information in their registration statements and annual reports.",
//     url: "/resource/newsletter/april2024",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "April 01, 2024",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "Transform Your Business With Help of ESG software",
//     url: "choose-wisely-esg-and-sustainability-reporting-software-for-your-business",
//     image: "Blogs/blog-32-image-1.jpg",
//     content:
//       "Environmental, social, and governance metrics assist organizations to measure their sustainability performances. The prominent role of the best ESG software is to track, measure, and enhance the sustainability performance along with mitigating the risk. Many companies are experiencing their sustainability teams to struggle in choosing the ESG software for their businesses and at the same time be mindful of the criteria before buying an ESG software in 2024.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "Apr 17, 2024",
//     status: "9 min read",
//     type: "blogs",
//   },
//   {
//     title:
//       "FCA Introduces Expanded Sustainability Disclosure Rules for Portfolio Managers.",
//     title1: "May 2024",
//     image: "images/may2024-1.jpg",
//     content:
//       "The Financial Conduct Authority (FCA) has initiated steps to extend its Sustainability Disclosure Requirements (SDR) to include portfolio managers, broadening the scope of regulations initially focused on asset managers.",
//     url: "/resource/newsletter/may2024",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 01, 2024",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "Machine Learning & Predictive Analytics: A Game-Changer In The Fight Against Climate Change",
//     image: "Media/machine-learning-and-predictive-analytics.png",
//     content:
//       "The climate economics index stress-tests reveal that climate change will impact 48 nations, encompassing 90% of the global economy, and evaluate their overall climate resilience",
//     url: "https://inc42.com/resources/machine-learning-predictive-analytics-a-game-changer-in-the-fight-against-climate-change/",
//     websiteName: "inc42.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 5, 2024",
//     status: "2 min read",
//     type: "newsroom",
//   },
//   {
//     title:
//       "Beyond Carbon Neutrality: A Look at the Multifaceted ESG Challenges Facing Indian Industry",
//     image: "Media/beyond-carbon-neutrality.jpg",
//     content:
//       "The Indian industrial sector stands at a critical juncture. While achieving carbon neutrality remains a prominent environmental goal, a broader spectrum of environmental, social, and governance (ESG) concerns demands immediate attention. Embracing a holistic ESG framework is no longer a peripheral consideration; it is a strategic imperative for ensuring sustainable industrial growth and fostering India's global competitiveness.",
//     url: "https://timestech.in/beyond-carbon-neutrality-a-look-at-the-multifaceted-esg-challenges-facing-indian-industry/",
//     websiteName: "timestech.in",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 17, 2024",
//     status: "2 min read",
//     type: "newsroom",
//   },
//   {
//     title:
//       "Updapt | ESG Reporting Software For Real Estate Portfolio and Investors",
//     url: "updapt-esg-reporting-software-for-real-estate-portfolio-and-investors",
//     image: "Blogs/blog-33-image-1.jpg",
//     content:
//       "The growing necessity of ESG in various industries which includes real estate is quite prevalent. Investors, stakeholders and financial firms are seeking out organizations with potential ESG practices while comprehending the importance of ESG at the same time.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "May 22, 2024",
//     status: "6 min read",
//     type: "blogs",
//   },
//   {
//     title:
//       "In an Interview with Mr. Satish Ramchandani Co-Founder, Updapt an ESG Tech Co.",
//     image: "Media/interview-with-mr-satish-ramchandani.png",
//     content:
//       "1. Can you provide an overview of Updapt's technological infrastructure and howit supports the tracking and monitoring of the ESG data lifecycle for enterprises and funds? Updapt offers advanced SaaS solutions for robust ESG data lifecycle management, catering to mid-sized and large enterprises, as well as investment funds globally. Our platform digitizes the entire ESG data management, monitoring, and reporting process, it also ensures alignment with international sustainability frameworks and standards such as GRI, SASB, CSRD, ESRS, BRSR, TCFD, CDP, DJSI and more.",
//     url: "https://m.dailyhunt.in/news/india/english/republic+news+india-epaper-dhfacc36dfce9c4bb68db0e89d033c921b/in+an+interview+with+mr+satish+ramchandani+cofounder+updapt+an+esg+tech+co-newsid-dhfacc36dfce9c4bb68db0e89d033c921b_12586300198211efba3aed34fb9076c2?sm=Y",
//     websiteName: "dailyhunt.in",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 23, 2024",
//     status: "2 min read",
//     type: "newsroom",
//   },
//   {
//     title:
//       "Algorithmic Governance: How AI Can Ensure ESG Compliance and Accountability",
//     image: "Media/algorithmic-governance.png",
//     content:
//       "Putting Environment Social and Governance (ESG) criteria in corporate strategies has been the most essential element of creating sustainable companies that care about society and can make progress financially.",
//     url: "https://www.techiexpert.com/algorithmic-governance-how-ai-can-ensure-esg-compliance-and-accountability/",
//     websiteName: "techiexpert.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "May 28, 2024",
//     status: "2 min read",
//     type: "newsroom",
//   },
//   {
//     title:
//       "GRI Unveils 'CSRD Essentials' to Streamline EU Sustainability Reporting.",
//     title1: "June 2024",
//     image: "images/june2024-1.svg",
//     content:
//       "The Global Reporting Initiative (GRI) has introduced a new series titled 'CSRD Essentials' to help companies navigate the complexities of the EU's Corporate Sustainability Reporting Directive (CSRD).",
//     url: "/resource/newsletter/june2024",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 01, 2024",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "ESG Tech Solutions for a Sustainable Future? Exclusive Chat With Satish Ramchandani of Updapt| Watch",
//     image: "Media/solutions-for-a-sustainable-future.jpg",
//     content:
//       "Satish Ramchandani, an experienced finance professional, co-founded Updapt in 2021, an ESG tech firm offering innovative SaaS solutions for sustainability management. As Chief Business Officer, he is spearheading Updapt's mission to revolutionize ESG technology. In this interview, Ramchandani sheds light on Updapt's offerings and the significance of ESG in today's business landscape.",
//     url: "https://www.youtube.com/watch?v=_b747N1rOtU",
//     websiteName: "youtube.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 19, 2024",
//     status: "12 min read",
//     type: "media",
//   },
//   {
//     title: "Facility Management case study",

//     image: "images/facilityManagement.jpg",
//     content:
//       "Client is a multi-award-winning integrated facilities management provider in the UAE. It commands an exceptionally high level of client retention and has enjoyed continuous growth since its formation in 2002. Integrated facilities management (FM) provider, has implemented several targeted strategies and innovations to intensify efforts to provide sustainable FM solutions to its clients and support the UAE's vision to create a more sustainable environment. The organisation has launched 'Together for Sustainability' — a holistic approach to reducing the environmental impact of its operations through targeted sustainability programmes.",
//     url: "/resource/case-study/esg-reporting-for-facility-management",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "June 26, 2024",
//     status: "4 min read",
//     type: "case study",
//     sameTab: true,
//   },
//   {
//     title: "Denmark Unveils World's First Agricultural Carbon Tax.",
//     title1: "July 2024",
//     image: "images/july2024-1.jpg",
//     content:
//       "Denmark has announced the world's first carbon tax targeting agricultural emissions. This initiative is part of the 'Green Tripartite' agreement, aiming to significantly reduce the country's greenhouse gas emissions.",
//     url: "/resource/newsletter/july2024",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "July 01, 2024",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "Updapt - An ESG Tech Co. partners with Grant Thornton Advisory East Africa to empower East African Businesses in their Sustainability Journey.",
//     image: "Media/mediaTwo.jpg",
//     content:
//       "Updapt - An ESG Tech Co, announced an alliance with Grant Thornton Advisory East Africa Limited, part of Grant Thornton International Ltd, one of the leading Audit, Assurance, and Advisory companies, headquartered in London, UK, and operating across more than 149 countries.",
//     url: "updapt-an-esg-tech-co-partners-with-grant-thornton-advisory-east-africa",
//     websiteName: "updaptcsr.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "July 05, 2024",
//     status: "2 min read",
//     type: "newsroom-media",
//   },
//   {
//     title:
//       "Driving Sustainability: ESG Innovation in the Middle East | Syed Waqar Andrabi | #TGV510",
//     image: "Media/driving-sustainability-in-the-middle-east.jpg",
//     content:
//       "Tune into #TGV510 to get clarity on the above topic. Here are the pointers from Syed Waqar's conversation with Naveen Samala on The Guiding Voice",
//     url: "https://www.youtube.com/watch?v=vsUCLF6pRTU",
//     websiteName: "youtube.com",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "July 6, 2024",
//     status: "26 min read",
//     type: "media",
//   },
//   {
//     title: "ESG Software for manufacturing industries.",
//     url: "esg-software-for-manufacturing-industries",
//     image: "Blogs/blog-34-image-1.jpg",
//     content:
//       "Over the past 25 years, there has been a remarkable rapid transformation in the industrial landscape worldwide. The manufacturing industry (link to blog of Understanding Net Zero Emission Targets)  is also on the verge of such an industrial revolution, driven by increased competition, changing customer expectations, supply chain challenges, and natural challenges like global warming and climatic changes.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "July 24, 2024",
//     status: "6 min read",
//     type: "blogs",
//   },
//   {
//     title: "UK Announces $10 Billion Platform for Clean Energy Development.",
//     title1: "August 2024",
//     image: "images/august2024-1.jpg",
//     content:
//       "The UK government has unveiled a significant initiative with the introduction of the Great British Energy Bill and the launch of a $10 billion platform dedicated to the development of clean energy projects. ",
//     url: "/resource/newsletter/august2024",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "August 01, 2024",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title: "New Zealand Tightens Carbon Market with Emission Credit Reduction.",
//     title1: "September 2024",
//     image: "images/september2024-1.jpg",
//     content:
//       "New Zealand has recently revised its Emissions Trading Scheme (ETS) by reducing the number of available emission credits by half, aiming to intensify its efforts toward meeting its climate goals. This strategic adjustment is a response to the growing urgency to address climate change, aligning the country's policies with global standards.",
//     url: "/resource/newsletter/september2024",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "September 01, 2024",
//     status: "4 min read",
//     type: "newsletter",
//     sameTab: true,
//   },

//   {
//     title: "ESG Software for the pharmaceutical industry.",
//     url: "esg-software-for-pharmaceutical-industries",
//     image: "Blogs/blog-35-img1.png",
//     content:
//       "Environmental, social, and governance (ESG) criteria are inevitable in today's modern business world.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "August 06, 2024",
//     status: "9 min read",
//     type: "blogs",
//   },
//   {
//     title:
//       "Hong Kong Proposes Global Standard Sustainability Reporting to Enhance Corporate Transparency.",
//     title1: "October 2024",
//     image: "images/october2024-1.jpeg",
//     content:
//       "Hong Kong is taking a significant step towards standardizing sustainability reporting by aligning its new reporting framework with global standards.",
//     url: "/resource/newsletter/october2024",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "October 01, 2024",
//     status: "7 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
//   {
//     title:
//       "What are the ESG Frameworks Have in ESG Reporting? Choose the Right One.",
//     url: "esg-frameworks-have-in-esg-reporting",
//     image: "Blogs/blog-36-img1.jpg",
//     content:
//       "ESG Reporting has numerous ESG Frameworks Includes BRSR, GRI,TCFD,CSRD,SASB.  It implies a set of comprehensive ESG guidance to help identify the ESG Risk.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "September 05, 2024",
//     status: "7 min read",
//     type: "blogs",
//   },
//   {
//     title: "What is Carbon accounting : Know More About Carbon Accounting",
//     url: "what-is-carbon-accounting",
//     image: "Blogs/blog-37-img1.png",
//     content:
//       "Carbon accounting is measuring  green gas emission or carbon emission that is emitted by organizations. It includes direct and indirect emissions.",
//     authorIimage: "Blogs/Deeksha.svg",
//     date: "October 26, 2024",
//     status: "7 min read",
//     type: "blogs",
//   },

//   {
//     title:
//       "Germany Allocates $3 Billion to Aid Industry Shift Towards Carbon Neutrality.",
//     title1: "November 2024",
//     image: "images/nov2024-1.jpeg",
//     content:
//       "Germany has allocated $3.1 billion to help decarbonize key industrial sectors such as chemicals, glass, and paper as part of its goal to become climate-neutral by 2045.",
//     url: "/resource/newsletter/november2024",
//     // url: "/resource/newsletter?month=november&year=2024",
//     author: "Deeksha",
//     authorIimage: "Blogs/Chinmayi.svg",
//     date: "November 04, 2024",
//     status: "7 min read",
//     type: "newsletter",
//     sameTab: true,
//   },
// ];

// export default resourceValues;
/** @format */

import November from "../../assets/pdf/November2020Newsletter.pdf";
import December from "../../assets/pdf/December2020Newsletter.pdf";
import January from "../../assets/pdf/January2021Newsletter.pdf";
import February from "../../assets/pdf/February2021Newsletter.pdf";
import March from "../../assets/pdf/March2021Newsletter.pdf";
import April from "../../assets/pdf/April2021Newsletter.pdf";
import May from "../../assets/pdf/May2021Newsletter.pdf";
import June from "../../assets/pdf/June2021Newsletter.pdf";
import July from "../../assets/pdf/July2021Newsletter.pdf";
import August from "../../assets/pdf/August2021Newsletter.pdf";
import September from "../../assets/pdf/September2021Newsletter.pdf";
import October from "../../assets/pdf/October2021Newsletter.pdf";
import November2021 from "../../assets/pdf/November2021Newsletter.pdf";
import December2021 from "../../assets/pdf/December2021.pdf";
import January2022 from "../../assets/pdf/Newsletter - January2022.pdf";
import February2022 from "../../assets/pdf/February2022Newsletter.pdf";
import March2022 from "../../assets/pdf/March2022Newsletter.pdf";
import April2022 from "../../assets/pdf/April2022Newsletter.pdf";
import May2022 from "../../assets/pdf/May2022Newsletter.pdf";
import June2022 from "../../assets/pdf/June2022Newsletter.pdf";
import July2022 from "../../assets/pdf/July2022Newsletter.pdf";
import August2022 from "../../assets/pdf/August2022Newsletter.pdf";
import September2022 from "../../assets/pdf/September2022Newsletter.pdf";

const resourceValues = [
  {
    title: "November 2020",
    image: "Newsletter/nov-2020.svg",
    url: November,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov, 2020",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "December 2020",
    image: "Newsletter/dec 2020.svg",
    url: December,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec, 2020",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "January 2021",
    image: "Newsletter/jan 2021.svg",
    url: January,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jan, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "February 2021",
    image: "Newsletter/feb 2021.svg",
    url: February,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Feb, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "March 2021",
    image: "Newsletter/mar 2021.svg",
    url: March,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Mar, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "April 2021",
    image: "Newsletter/apr 2021.svg",
    url: April,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Apr, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "May 2021",
    image: "Newsletter/may 2021.svg",
    url: May,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "June 2021",
    image: "Newsletter/june 2021.svg",
    url: June,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jun, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "July 2021",
    image: "Newsletter/july 2021.svg",
    url: July,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jul, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "August 2021",
    image: "Newsletter/aug2021.svg",
    url: August,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Aug, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "September 2021",
    image: "Newsletter/sept 2021.svg",
    url: September,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Sep, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "October 2021",
    image: "Newsletter/oct 2021.svg",
    url: October,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "oct, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "November 2021",
    image: "Newsletter/nov 2021.svg",
    url: November2021,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov, 2021",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "December 2021",
    image: "Newsletter/dec 2021.svg",
    url: December2021,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec, 2021",
    status: "2 min read",
    type: "newsletter",
  },

  {
    title: "January 2022",
    image: "Newsletter/jan 2022.svg",
    url: January2022,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jan, 2022",
    status: "2 min read",
    type: "newsletter",
  },
  {
    title: "SDGs 101",
    url: "sdgs-101",
    image: "Blogs/blog-1.jpg",

    content: [
      " A Guide to understanding the UN assigned SDG’s 1. No Poverty..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec 22, 2019",
    status: "6 min read",
    type: "blogs",
  },
  {
    title: "Sustainability- Towards a Better Future",
    url: "sustainability-let-s-make-it-better",
    image: "Blogs/blog-2.jpg",

    content: [
      "“Climate Change”, “Sustainable Development Goals”, “The Paris Agreem..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec 23, 2019",
    status: "4 min read",
    type: "blogs",
  },
  {
    title: "Responsible Companies for a Responsible Nation",
    url: "responsible-companies-and-a-responsible-nation-an-insight-to-csr-in-india",
    image: "Blogs/blog-3.jpg",

    content: [
      " An insight to CSR in India Corporate Social Responsibility (CSR) has ambiguous defini..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec 24, 2019",
    status: "4 min read",
    type: "blogs",
  },
  {
    title: "Sustainability and the Fashion Industry",
    url: "sustainability-and-the-fashion-industry",
    image: "Blogs/blog-4.svg",

    content: [
      " We often read reports of incidence of forest fires destroying natural habittats and errat..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec 25, 2019",
    status: "4 min read",
    type: "blogs",
  },
  {
    title: "How the SDGs reinforce CSR in india",
    url: "the-road-to-sustainable-development-how-the-sdgs-are-reinforced-in-india",
    image: "Blogs/blog-6.jpg",

    content: [
      "CSR funding can help us tap into resources that are required for environmental and so..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jan 3, 2020",
    status: "4 min read",
    type: "blogs",
  },
  {
    title: "Sea level rise and its impact",
    url: "sea-level-rise-and-it-s-impact",
    image: "Blogs/blog-7.jpg",

    content: [
      "The oceans make up about 70% of our planet. Oceans sequester large amounts of..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 4, 2020",
    status: "2 min read",
    type: "blogs",
  },

  {
    title: "World Environment Day",
    url: "world-environment-day",
    image: "Blogs/blog-8.jpg",

    content: [
      "We rely on nature for everything- the air we breathe, the water we consume, energy, fo..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jun 5, 2020",
    status: "2 min read",
    type: "blogs",
  },
  {
    title: "Sustainability and the Pandemic",
    url: "sustainability-and-the-pandemic",
    image: "Blogs/blog-4.jpg",

    content: [
      "The Corona-virus has been wreaking havoc across all parts of the world since the start..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jun 19, 2020",
    status: "3 min read",
    type: "blogs",
  },
  {
    title: "Why Diversity is integral to a sustainable Business?",
    url: "why-diversity-in-integral-to-a-sustainable-business",
    image: "Blogs/blog-3a.jpg",

    content: [
      "The world we live in is divided; diversity exists in the form of culture,race and soci..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jun 27, 2020",
    status: "3 min read",
    type: "blogs",
  },
  {
    title: "The Need for a sustainable Business Behavior",
    url: "the-need-for-a-sustainable-business-behavior",
    image: "Blogs/blog-2a.jpg",

    content:
      "The world has faced some unprecedented challenges during the recent year,erratic..",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec 9, 2020",
    status: "2 min read",
    type: "blogs",
  },
  {
    title: "What happens when we discard our phone for a 'Smarter' one",
    url: "what-happens-when-we-discard-our-phone-for-a-smarter-one",
    image: "Blogs/blog-8a.jpg",

    content: [
      "The market for electrical and electronic equipment has seen an exponential growth..",
    ],
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jan 08, 2021",
    status: "3 min read",
    type: "blogs",
  },
  {
    title: "Understanding Net Zero Emission Target",
    url: "what-do-net-zero-emission-targets-mean",
    image: "Blogs/blog-12.jpg",

    content:
      "Lately we have been reading stories about a number of organizations like Ikea, Microso..",
    author: "Chinmayi",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jan 8, 2021",
    status: "4 min read",
    type: "blogs",
  },

  {
    title: "Are EVs the way of the future?",
    url: "are-evs-the-way-of-the-future",
    image: "Blogs/blog-13.jpg",

    content:
      " As fuel costs continue to rise, more individuals are looking for alternate solu..",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Oct 28, 2021",
    status: "4 min read",
    type: "blogs",
  },
  {
    title: "Debunking the Myths around Climate Change",
    url: "debunking-the-myths",
    image: "Blogs/blog14.jpg",

    content:
      "With Climate Change picking up steam, there has been a lot of confusion and..",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov 09 , 2021",
    status: "3 min read",
    type: "blogs",
  },
  {
    title: "Understanding the GreenHouse Gases-C02",
    url: "understanding-the-greenhouse-gases",
    image: "Blogs/blog15.jpg",

    content:
      "Our planet is becoming warmer. The heat is melting ice caps, changing..",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov 16, 2021",
    status: "3 min read",
    type: "blogs",
  },
  {
    title: "Lets talk about Carbon Taxes",
    url: "let's-talk-about-carbon-taxes",
    image: "Blogs/blog16.jpg",
    content:
      " The first warning of global warming came a century ago. With the discovery of the..",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov 23, 2021",
    status: "3 min read",
    type: "blogs",
  },
  {
    title: "Understanding the Greenhouse Gases- CH4",
    url: "understanding-the-greenhouse-gases-ch4",
    image: "Blogs/blog17.jpg",
    content:
      " Climate change conversations have focused too little attention on methane..",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec 07, 2021",
    status: "3 min read",
    type: "blogs",
  },
  {
    title: "Extreme weather events and how they are linked to Climate Change",
    url: "extreme-weather-events-and-how-they-are-linked-to-climate-change",
    image: "Blogs/b18.jpg",

    content: "Climate disasters are part of the natural cycle of the weather..",
    author: "Deeksha",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Dec 16, 2021",
    status: "2 min read",
    type: "blogs",
  },
  {
    title: "Shailesh Haribhakti joins Advisory Board of Updapt",
    image: "Media/Shailesh_sir_image.svg",
    content:
      "Updapt is a new-age technology-driven company, providing comprehensive easy-to-use...",
    url: "https://www.livemint.com/brand-post/shailesh-haribhakti-joins-advisory-board-of-updapt-a-digital-esg-platform-co-11642426951232.html",
    websiteName: "livemint.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jan 17, 2022",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title: "February Newsletter 2022",
    image: "Newsletter/feb-2022.svg",
    content:
      "Climate change poses the most significant long-term threat to the global economy.",
    url: February2022,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Feb 01, 2022",
    status: "4 min read",
    type: "newsletter",
  },
  {
    title: "March Newsletter 2022",
    image: "Newsletter/mar-2022.svg",
    content:
      "The Institutional Investors Group on Climate Change (IIGCC) has published a new component for the Paris Aligned Investment Initiative (PAII) called Net Zero Investment Framework (NZIF).",
    url: March2022,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Mar 01, 2022",
    status: "4 min read",
    type: "newsletter",
  },
  {
    title: "Understanding Climate Justice",
    url: "understanding-climate-justice",
    image: "Blogs/b19.jpg",
    content:
      "In December 2019, Typhoon Ursula ravaged the Philippines, displacing thousands of people..",
    author: "Deeksha",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Mar 08, 2022",
    status: "3 min read",
    type: "blogs",
  },
  {
    title: "April Newsletter 2022",
    image: "Newsletter/april-2022.svg",
    content:
      "Under a new radical rule, the SEC has proposed for the first time that hundreds of businesses",
    url: April2022,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "April 01, 2022",
    status: "4 min read",
    type: "newsletter",
  },
  {
    title: "May Newsletter 2022",
    image: "Newsletter/may 2022.svg",
    content:
      "Almost everything on earth is dependent on nature, yet most companies don’t count nature- related risks and opportunities in",
    url: May2022,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 01, 2022",
    status: "4 min read",
    type: "newsletter",
  },
  {
    title: "Accenture Sustainability Value Accelerator programme",
    image: "Media/accenture_sust_val_updapt_logo_img.svg",
    content:
      "Accenture Sustainability Value Accelerator programme, which was launched in collaboration with...",
    url: "https://yourstory.com/2022/05/accenture-sustainability-value-accelerator-programme/amp",
    websiteName: "yourstory.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 17, 2022",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title: "CIIE.CO Cohort announcement",
    image: "Media/cohort_announcement.svg",
    content:
      "We are proud to announce that 10 startups, who are furthering the #sustainability agenda, have...",
    url: "https://www.linkedin.com/posts/ciieco_sustainability-accelerator-cohortlaunch-activity-6931945833001037824-0RGG?utm_source=linkedin_share&utm_medium=member_desktop_web",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 18, 2022",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title: "June Newsletter 2022",
    image: "Newsletter/june-2022.svg",
    content:
      "Initiative Climat International (ICI), a global practitioner-led community of private equity firms and investors, is developing a new standard/guidance for accounting and reporting greenhouse gas emissions for the private equity sector.",
    url: June2022,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 01, 2022",
    status: "4 min read",
    type: "newsletter",
  },
  {
    title: "10 startups fighting against climate change",
    image: "Media/plastic_bag.svg",
    content:
      "As we celebrate World Environment on 5 June, we need to take a look back at the innovative solutions brought in by startups...",
    url: "https://www.printweek.in/news/10-startups-fighting-against-climate-change-56225",
    websiteName: "printweek.in",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 03, 2022",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title: "ESG Summit 2022 organized by India CSR Network",
    image: "Media/ESG_India.svg",
    content:
      "#ESG Summit 2022 was organized by India CSR Network with theme 'ESG for All, Sustainability First' on May 31, 2022 in...",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:6938427735908184064/",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 04, 2022",
    status: "4 min read",
    type: "media",
  },
  {
    title: "Updapt - an ESG Tech Co. on LinkedIn: #Digital #Technology #Awards",
    image: "Media/satish_ram.svg",
    content:
      "IMC #Digital #Technology #Awards ceremony was organized by IMC Chamber Of Commerce and Industry (IMC) on 22nd...",
    url: "https://www.linkedin.com/posts/updapt-csr_digital-technology-awards-activity-6946415559387283456-k-3T/?utm_source=linkedin_share&utm_medium=member_desktop_web",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 28, 2022",
    status: "4 min read",
    type: "media",
  },
  {
    title: "July Newsletter 2022",
    image: "Newsletter/july-2022.svg",
    content:
      "53% of Small and Medium Enterprises (SMEs) investing in their sustainability",
    url: July2022,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jul 10, 2022",
    status: "6 min read",
    type: "newsletter",
  },
  {
    title: "August Newsletter 2022",
    image: "Newsletter/aug-2022.svg",
    content:
      "Banks must sharpen their focus on climate risk - European Central Bank",
    url: August2022,
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Aug 02, 2022",
    status: "6 min read",
    type: "newsletter",
  },

  {
    title: "September Newsletter 2022",
    image: "Newsletter/sep-2022.svg",
    content:
      "Inflation Reduction Act - the most significant investment turned into law in the US",
    url: September2022,

    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Sep 01, 2022",
    status: "6 min read",
    type: "newsletter",
  },

  {
    title: "Let’s talk about Biofuels",
    url: "let's-talk-about-biofuels",
    image: "Blogs/b20.jpg",
    content:
      "Using biofuels for sustainability has been around since the 1800s.",
    author: "Meenakshi",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Sep 07, 2022",
    status: "2 min read",
    type: "blogs",
  },

  {
    title: "Where does the world stand with SDG 2030 today?",
    url: "where-does-the-world-stand-with-sdg-2030-today",
    image: "Blogs/giftboxes.jpg",
    content:
      "The United Nations adopted 17 Sustainable Development Goals in 2015 as an action to eradicate poverty, sustain the earth, and secure the goal of peace and prosperity by the year 2030.",
    author: "Alice Patricia",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Sep 15, 2022",
    status: "2 min read",
    type: "blogs",
  },

  {
    title:
      "Updapt - new partnership announcement with DNV Supply Chain and Product Assurance (SCPA)",
    image: "Media/dnv_updapt.jpg",
    content:
      "Updapt is glad to announce a partnership with DNV Supply Chain and Product Assurance (SCPA), one of...",
    url: "dnv-scpa-collaborates-with-updapt-an-esg-tech-co-to-transform-your-sustainability-journey-230553",
    websiteName: "updaptcsr.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Sept 23, 2022",
    status: "2 min read",
    type: "newsroom-media",
  },
  {
    title: "October Newsletter 2022",
    title1: "October 2022",
    image: "Newsletter/newsletter1.jpg",
    content:
      "Like many other countries, UAE aims6 to reach net-zero carbon emissions or toward a green economy...",
    url: "/resource/newsletter?month=october&year=2022",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Oct 01, 2022",
    status: "4 min read",
    sameTab: true,
    type: "newsletter",
  },
  {
    title:
      "Mainstreaming sustainability: how Indian startups are warming up to ESG issues",
    image: "images/Oct-Blog-image.jpg",
    content:
      "Given the agriculture and climate-related issues in India, there is a huge opportunity for ESG ventures to deliver high internal rate of returns that can attract VCs",
    url: "https://economictimes.indiatimes.com/prime/corporate-governance/mainstreaming-sustainability-how-indian-startups-are-warming-up-to-esg-issues/primearticleshow/94769904.cms",
    websiteName: "economictimes.indiatimes.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Oct 12, 2022",
    status: "4 min read",
    type: "media",
  },

  {
    title: "Importance of an ESG Report for a Better ESG Management",
    url: "importance-of-an-esg-report-for-a-better-esg-management",
    image: "Blogs/esg-img.jpg",
    content:
      "The investment community is becoming increasingly aware of ESG risks and opportunities.",
    author: "Veenapani Joshi",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Oct 17, 2022",
    status: "2 min read",
    type: "blogs",
  },

  {
    title: "Updapt - an ESG Tech Co. was part of ASSOCHAM",
    image: "Media/esg-conclave.jpg",
    content:
      "Updapt - an ESG Tech Co. was part of ASSOCHAM (The Associated Chambers of Commerce and Industry of India) ESG Conclave 2022 held on 23rd September 2022 with theme ‘Challenges, Opportunities & The Way Forward’.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:6980192749761277952",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Oct 19, 2022",
    status: "2 min read",
    type: "media",
  },
  {
    title:
      "Building a Sustainable Tomorrow, Transforming Risks into Opportunities",
    image: "Media/summit.jpg",
    content:
      "Themed 'Building a Sustainable Tomorrow, Transforming Risks into Opportunities', the 17th Sustainability Summit 2022 organized...",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:6982685107232886784",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Oct 19, 2022",
    status: "2 min read",
    type: "media",
  },
  {
    title:
      "Updapt - an ESG Tech Co. to run the #Sustainability #Awareness webinar for RSA Global",
    image: "Media/rsa_global.jpg",
    content:
      "It was a pleasure for Updapt - an ESG Tech Co. to run the #Sustainability #Awareness webinar for RSA Global, a global logistics and supply chain solutions company headquartered in UAE.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:6985544000778719233",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Oct 19, 2022",
    status: "2 min read",
    type: "media",
  },
  {
    title: "November Newsletter 2022",
    title1: "November 2022",
    image: "Newsletter/newsletter2.jpg",
    content:
      "The Association of Banks in Singapore (ABS) has launched new sustainable private banking...",
    url: "/resource/newsletter?month=november&year=2022",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov 01, 2022",
    status: "4 min read",
    sameTab: true,
    type: "newsletter",
  },
  {
    title:
      "As NTPC improves its ESG score, why it may be too early to celebrate. (Hint: think coal)",
    image: "Media/coal_work.jpg",
    content:
      "As per Morningstar Sustainalytics July ratings, the power producer's ESG score has improved from ‘severe to ‘high-risk.",
    url: "https://economictimes.indiatimes.com/prime/energy/as-ntpc-betters-its-esg-score-why-it-is-t[…]elebrate-hint-think-coal/primearticleshow/95129284.cms",
    websiteName: "economictimes.indiatimes.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov 03, 2022",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title: "How can we achieve decarbonization?",
    url: "how-can-we-achieve-decarbonization",
    image: "Blogs/Rectangle23.jpg",
    content:
      "Decarbonization is critical in limiting global warming and for companies declaring carbon neutral or net zero by 2050. ",
    author: "Meenakshi",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Nov 09, 2022",
    status: "2 min read",
    type: "blogs",
  },
  {
    title: "Technology Can Help Businesses Make ESG Reporting Easy",
    image: "Media/satishmedia.jpg",
    content:
      "Currently, ESG data management and disclosure is one of the key challenges faced by companies in India including over 80% of the 1,000 listed companies along with decarbonization execution and capacity building.",
    url: "https://www.outlookindia.com/business/technology-can-help-businesses-make-esg-reporting-easy-news-239058",
    websiteName: "outlookindia.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov 22, 2022",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title:
      "IFRS announces CDP's incorporation of the ISSB Climate-related Disclosures.  ",
    title1: "December 2022",
    image: "Newsletter/last.jpg",
    content:
      "An announcement was made by the IFRS to the global market ahead of finance day at COP27 about CDP's...",
    url: "/resource/newsletter?month=december&year=2022",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Dec 01, 2022",
    status: "4 min read",
    sameTab: true,
    type: "newsletter",
  },
  {
    title: "Benefits of Impact Investment",
    url: "benefits-of-impact-investment",
    image: "Blogs/blog24.jpg",
    content:
      "Impact investment market supplies capital to address the world's most crucial challenges in sustainable farming, conventional energy, conservation of resources, and microfinance, along with inexpensive and available essential services.",
    author: "Meenakshi",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Dec 19, 2022",
    status: "2 min read",
    type: "blogs",
  },
  {
    title: "Introduction and Recap COP27",
    image: "images/podcast-theme.jpg",
    url: "introduction-and-recap-cop27",
    date: "Jan 01, 2023",
    duration: "10 min",
    status: "10 min",
    type: "podcast",
    host: "Meenakshi Venkatachalapathy",
    content:
      "Our focused podcast series concentrates on hot topics and trending ESG industry news.",
    synopsis:
      " COP27, the 27th session of the conference of the parties to the United Nations Framework Convention on climate change, is the topic for our podcast episode for environment and sustainability enthusiasts. Join us as our speaker, Meenakshi Venkatachalapathy, Sustainability consultant at Updapt, give us a quick recap of the #CO27 event and discuss trends in growth and decision-making. The podcast divulges Updapt's perspectives on the changing climate and our focus on the continuous pursuit of sustainability and economic and social responsibility.",
    podcast_audio: "podcast1.mp3",
  },

  {
    title:
      "Indian Parliament passes an Energy Conservation bill to promote non-fossil energy sources.",
    title1: "January 2023",
    image: "images/jan1.jpg",
    content:
      "The parliament of India have passed the energy conservation bill in both houses ( Lokha Sabha and Rajya Sabha)...",
    url: "/resource/newsletter?month=january&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jan 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Satish Ramchandani from Updapt: Software to monitor and cut carbon emissions",
    image: "images/podcast-2.jpg",
    url: "software-to-monitor-and-cut-carbon-emissions",
    date: "Jan 10, 2023",
    duration: "28 min",
    status: "28 min",
    type: "podcast",
    host: "Bharti Chhatre Krishnan",
    synopsis:
      "CSR and ESG were once used interchangeably, and mostly referred to programmes for underserved communities (for example- water coolers in village schools). This information was typically kept in excel spreadsheets and was reviewed near reporting deadlines. However, net zero pledges are changing ESG reporting. Organizations must now continuously measure their carbon footprints because regulators, investors, suppliers, as well as millennial customers, want to know their green credentials. It's no surprise that ESG tech is a growing opportunity, with investors across the world queuing up to invest in ESG tech companies.",
    podcast_audio: "podcast2.mp3",
  },
  {
    title:
      "The Federal Reserve Board (FRB) begins the pilot climate scenario analysis exercise",
    title1: "February 2023",
    image: "images/feb2023-1.jpg",
    content:
      "The Federal Reserve Board is conducting a climate scenario analysis exercise and has released an instruction document to understand...",

    url: "/resource/newsletter?month=february&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Feb 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "Mr. Erik Solheim joins Advisory Board of Updapt (an ESG Tech Co.)",
    image: "Media/erik-solheim.jpg",
    content:
      "Updapt, a SaaS based ESG Solution Co., announced today that Mr. Erik Solheim, a Green Leader and Norwegian Diplomat, has joined their Advisory Board to mentor the leadership team in their journey of solving core environment issues by building sustainability led digital solutions for businesses in global markets including European Union (EU) region.",
    url: "https://londondailypost.com/mr-erik-solheim-joins-advisory-board-of-updapt-an-esg-tech-co/",
    websiteName: "londondailypost.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Jan 24, 2023",
    status: "3 min read",
    type: "newsroom",
  },
  {
    title: "Internal Carbon Pricing",
    url: "internal-carbon-pricing",
    image: "Blogs/blog25.jpg",
    content:
      "Carbon risk and its effects are fast approaching, as seen in January 2021, when the Bank of England alerted businesses about the surge in carbon price to $100 per ton.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Feb 20, 2023",
    status: "2 min read",
    type: "blogs",
  },
  {
    title: "Recycling and Carbon Emissions Conference (RACE Conferences)",
    image: "Media/race-conferences.jpg",
    content:
      "The recently concluded Recycling and Carbon Emissions Conference (RACE Conferences) on 4th February 2023 saw active participation from various businesses, agencies and experts in field of #wastemanagement, #plasticrecycling and #sustainability sharing insights on related regulations, challenges, technology and methods on how to reduce #emissions with recycling as a central component.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7031880387979096064",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Feb 17, 2023",
    status: "3 min read",
    type: "media",
  },
  {
    title:
      "SEBI, capital market regulator in India has released a new set of proposals seeking public consultation in ESG disclosure, ESG ratings and ESG investing.",
    title1: "March 2023",
    image: "images/newsletter-2023.png",
    content:
      "Following the Business Responsibility and Sustainability Reporting (BRSR), the Securities and Exchange Board of India (SEBI) has mandated the disclosure of ESG data by the top 1000 listed companies (based on market capitalization).",

    url: "/resource/newsletter?month=march&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "March 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "The World ESG Summit by Gulf Xellence",
    image: "Media/world-esg-summit.jpg",
    content:
      "The World ESG Summit by Gulf Xellence was conducted from 21 - 23 February 2023 in #Dubai bringing in thought leaders, corporations, government from #UAE, #SaudiArabia and other #GCC countries.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7036374438409318400",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Feb 28, 2023",
    status: "3 min read",
    type: "media",
  },
  {
    title: "ESG Leadership Summit 2023",
    image: "Media/esg-leadership-summit-2023.jpg",
    content:
      "Dun & Bradstreet India hosted the inaugural edition of 'ESG Leadership Summit 2023' on the theme 'Building A Sustainable Future' on March 15, 2023.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7044545011912163328",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "March 23, 2023",
    status: "3 min read",
    type: "media",
  },
  {
    title: 'Different terms, but the goal is "Let\'s go Net Zero!"',
    url: "different-terms-but-the-goal-is-lets-go-net-zero",
    image: "Blogs/blog26.jpg",
    content:
      "Net Zero is a global objective that must be driven at the national level, where its policies are followed locally by all countries, cities and businesses developing an action plan.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Mar 28, 2023",
    status: "2 min read",
    type: "blogs",
  },
  {
    title:
      "UAE is the first Gulf country to commit to net-zero emissions by 2050",
    title1: "April 2023",
    image: "images/apr2023-1.jpg",
    content:
      "Ahead of hosting COP28 later this year, The UAE government signed a Net Zero by 2050 charter, launched initially as a net zero 2050 strategic initiative at Expo 2020 in Dubai, followed by an announcement with the UN secretary general and at the COP27.",
    url: "/resource/newsletter?month=april&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "April 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "Climate Plans Lost In Data Matrix",
    image: "Media/climate-plans-lost-in-data-matrix.jpg",
    content:
      "Data gaps are throwing a spanner in the works for a world which is falling distressingly short of commitments to slow down, if not reverse, rapidly deteriorating global climate conditions and India is not an outlier.",
    url: "https://www.outlookbusiness.com/the-big-story-1/lead-story-8/climate-plans-lost-in-data-matrix-6741",
    websiteName: "outlookbusiness.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "April 01, 2023",
    status: "9 min read",
    type: "newsroom",
  },
  {
    title: "Hong Kong Exchange Requires Climate Disclosures from 2024.",
    title1: "May 2023",
    image: "images/may2023-1.jpg",
    content:
      "Hong Kong's Stock Exchange is set to require all listed companies to provide climate-related disclosures in line with the International Sustainability Standards Board's (ISSB) upcoming Climate Standard.",

    url: "/resource/newsletter?month=may&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Evolving Sustainability Practices and Global Trends: Insights from Capt. Tapas Majumdar",
    image: "Media/evolving-sustainability-practices-and-global-trends.jpg",
    content:
      "Capt. Tapas Majumdar, Founder Director @ The Sustainability Practitioners, Independent Director, Sustainability (ESG) Reporting & Assurance, Member Board of Studies (BOS) in discussion with Pratik Patankar, Associate Director at Updapt - an ESG Tech Co on evolving sustainability practices and global trends across economies.",
    url: "https://www.youtube.com/watch?v=4syxstY7wTA",
    websiteName: "youtube.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 18, 2023",
    status: "24 min read",
    type: "media",
  },
  {
    title:
      "The Australian government establishes the Net Zero Authority to oversee the smooth transition to a low-carbon economy.",
    title1: "June 2023",
    image: "images/june2023-1.jpg",
    content:
      "The Australian government's Net Zero Authority will guide the country's economic transformation towards achieving net-zero emissions, reskilling workers for the energy transition, and facilitating collaboration with industry and investors on transformation opportunities.",
    url: "/resource/newsletter?month=june&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "NASSCOM GreenTables | Green Transition With Tech",
    image: "Media/nasscom-green-tables.png",
    content:
      "Join us for this insightful discussion on 'Green Transition with Tech' by Santhosh Jayaram (Global Head - Sustainability, HCL Tech), Apurba Mitra (Associate Partner, KPMG India) & Anuj Duggal (Head - Sustainability & ESG, nasscom).",
    url: "https://www.linkedin.com/events/nasscomgreentables-greentransit7069649274807947264/comments/",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 08, 2023",
    status: "34 min",
    type: "media",
  },
  {
    title:
      "The Importance of Integrating DEI (Diversity, Equity, and Inclusion Practices) in the Workplace in achieving sustainable development goals",
    url: "the-importance-of-integrating-dei",
    image: "Blogs/blog27.jpg",
    content:
      "Integrating DEI practices in the workplace can significantly contribute to achieving sustainable development goals and create a more inclusive and equitable environment, leading to a more engaged and productive workforce where people from diverse backgrounds are empowered to bring their unique perspectives and skills.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Jun 27, 2023",
    status: "2 min read",
    type: "blogs",
  },
  {
    title: "UK and France Launch Joint Biodiversity Credits Initiative.",
    title1: "July 2023",
    image: "images/july2023-1.jpg",
    content:
      "The United Kingdom and France have joined forces to launch a biodiversity credits initiative to support companies in their efforts to contribute to nature recovery.",
    url: "/resource/newsletter?month=july&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "July 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Updapt - an ESG Tech Co., participated in #TiEcon2023, organized by TiE Mumbai.",
    image: "Media/tie-mumbai.jpg",
    content:
      "Updapt's participation in #TiEcon2023 showcased its commitment to #climatetech #innovation, engaging with industry leaders and investors, and exploring avenues for #growth and #collaboration.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7071849589049335808",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 08, 2023",
    status: "4 min read",
    type: "media",
  },
  {
    title: "How ESG is slowly making Indian businesses future-ready",
    image: "Media/how-esg-is--slowly-making-indian-businesses-future-ready.jpg",
    content:
      "From building a strong reputation to managing risks, navigating regulatory changes to attracting capital, and identifying innovation and market opportunities, embracing ESG can help businesses become future-ready",
    url: "https://www.businesstoday.in/interactive/longread/the-esg-imperative-indian-businesses-future-ready-265-20-06-2023",
    websiteName: "businesstoday.in",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 20, 2023",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title: "The Tech Push to ESG Data Management",
    image: "Media/the-tech-push-to-esg-data-management.jpg",
    content:
      "Data management platforms are helping buisnesses make informed decisions to optimise their environmental, social and governance regime by collecting and standardising data.",
    url: "https://www.outlookbusiness.com/the-big-story-1/lead-story-8/the-tech-push-to-esg-data-management-6789",
    websiteName: "outlookbusiness.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "July 01, 2023",
    status: "3 min read",
    type: "newsroom",
  },
  {
    title: "London Climate Action Week",
    image: "Media/london-climate-action-week.jpg",
    content:
      "#Updapt UK Limited was part of London Climate Action Week during 25th June to 2nd July 2023 and had exhibited in Reset Connect ExCeL London showcasing its innovative SaaS-based ESG solutions, empowering businesses to digitize and simplify their sustainability journey towards their #netzero goals.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7082969775340945409/",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "July 07, 2023",
    status: "5 min read",
    type: "media",
  },
  {
    title:
      "Singapore Proposes Mandatory Climate Reporting for Public and Private Companies, Aligned with International Standards.",
    title1: "August 2023",
    image: "images/august2023-1.jpg",
    content:
      "Singapore's Accounting and Corporate Regulatory Authority (ACRA) and Singapore Exchange Regulation (SGX RegCo) have proposed a new requirement for public and large private companies in the country to provide climate-related disclosures.",
    url: "/resource/newsletter?month=august&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "August 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "UpdaptESG All-in-One Sustainability Software",
    url: "updapt-esg-all-in-one-sustainability-software",
    image: "Blogs/updapt-esg-all-in-one.png",
    content:
      "In today's world, sustainability is a crucial aspect of any business. Organizations worldwide are striving to improve their Environmental, Social, and Governance (ESG) performance to meet the demands of a rapidly changing landscape.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Aug 22, 2023",
    status: "2 min read",
    type: "blogs",
  },
  {
    title:
      "EU Adopts Rules Requiring Product Emissions Reporting for New Import Carbon Tax.",
    title1: "September 2023",
    image: "images/sep2023-11.jpg",
    content:
      "The European Commission has introduced reporting rules for importers under the Carbon Border Adjustment Mechanism (CBAM), the EU's new carbon tax on imported goods, aiming to align carbon prices paid by European and non-EU producers.",
    url: "/resource/newsletter?month=september&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "September 07, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "Climate Litigation Risk and Best ESG Practices",
    image: "Media/climate-litigation-risk.jpg",
    content:
      "Bose Varghese, Senior Director - ESG, at Cyril Amarchand Mangaldas in discussion with  Pratik Patankar, Director at Updapt - an ESG Tech Co. on landscape of climate litigation risk and its efficient management through technology.",
    url: "https://www.youtube.com/watch?v=yVTTSjsMz60",
    websiteName: "youtube.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Sep 12, 2023",
    status: "26 min read",
    type: "media",
  },
  {
    title:
      " California Legislators Approve Bill Mandating Full Value Chain Emissions Disclosure by Companies.",
    title1: "October 2023",
    image: "images/oct2023-1.jpeg",
    content:
      " A new proposed California law that could require most large U.S. companies to disclose their full value chain greenhouse gas (GHG) emissions passed the state's Assembly.",
    url: "/resource/newsletter?month=october&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "Cement Industry Case Study",
    image: "images/cementIndustry.jpg",
    content:
      "The company is one of the India's pioneering homegrown cement companies. The company operates a manufacturing capacity of 35.9 MnT per annum (MTPA), across 14 cement plants and grinding units which are spread across 10 states.",
    // url: "/resource/case-study/esg-reporting-for-cement-companies",
    url: "/resource/case-study?title=esg-reporting-for-cement-companies",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 05, 2023",
    status: "4 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title: "Healthcare case study",
    image: "images/healthCare.jpg",
    content:
      "Client is multinational healthcare group. Apart from the eponymous hospital chain, the company also operates pharmacies, primary care, diagnostic centres, telehealth clinics, and digital healthcare services through its subsidiaries. With an annual revenue of US$1.8 billion and an employee strength of over 60,000, Client Hospitals desired to pioneer       sustainability and blend better ESG practices as part of its operations.",
    // url: "/resource/case-study/esg-reporting-for-healthcare-sector",
    url: "/resource/case-study?title=esg-reporting-for-healthcare-sector",

    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 07, 2023",
    status: "4 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title: "Pharma case study",

    image: "images/pharmaCase.jpg",
    content:
      "Client is India's one of the largest pharma company with a revenue of over USD 2 billion dollars, present in more than 80 countries and with over 47 manufacturing facilities across 6 countries. The organisation has a sustainability commitment to achieve Carbon and Water neutrality by the year 2025. Responsible resource consumption and sustainable operations form part of the organisation's vision and values.",
    // url: "/resource/case-study/esg-reporting-for-pharma-companies",
    url: "/resource/case-study?title=esg-reporting-for-pharma-companies",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 08, 2023",
    status: "5 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title: "Power & Renewables case study",

    image: "images/powerAndRenewables.jpg",
    content:
      "The company is the countries' largest integrated power company with an installed generation capacity of 14,110 MW. The organisation has a diversified line of business venturing into renewable energy generation, conventional energy generation, transmission and distribution including several others. With over 12.5 Mn distribution consumers and a legacy of 100+ years in the power generation business, the organisation along with its subsidiaries and joint entities is present across the entire power value chain. The organisation with a 30% clean energy portfolio has committed to being Carbon 'Net Zero' before 2045. It has also committed to Water neutrality before 2030 and ensuring Zero waste to landfills before 2030",
    // url: "/resource/case-study/esg-reporting-for-energy-sector",
    url: "/resource/case-study?title=esg-reporting-for-energy-sector",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 10, 2023",
    status: "3 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title: "Realty case study",

    image: "images/realtyCase.jpg",
    content:
      "Client is one of India's finest real estate developers that has offered more than 50,000 homes in the last five to seven years. The company develops residential real estate, focusing on affordability while offering premium and luxury housing, office spaces, retail spaces, and digital infrastructure properties in Mumbai, Thane, Pune, Bangalore & London. ",
    // url: "/resource/case-study/esg-reporting-for-real-estate-sector",
    url: "/resource/case-study?title=esg-reporting-for-real-estate-sector",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 15, 2023",
    status: "4 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title: "Supplier assessment case study",

    image: "images/supplierAssessmentCase.jpg",
    content:
      "One of our pharmaceutical partners have used our supplier assessment module to create a new “Code of conduct” survey for their 5000+ suppliers in their value chain. They created a custom survey consisting of 52 questions. The survey was qualitative in nature and wanted to assess the suppliers on the following topics",
    // url: "/resource/case-study/esg-reporting-for-supplier-assessment",
    url: "/resource/case-study?title=esg-reporting-for-supplier-assessment",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 16, 2023",
    status: "3 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title: "Waste management industry case study",

    image: "images/wasteManagementCase.jpg",
    content:
      "The client is a large waste management company and manages 7 million tons of waste every year and is also the first to establish a waste management facility specifically for biomedical, Industrial, and integrated municipal in the country.",
    // url: "/resource/case-study/esg-reporting-for-waste-management-companies",
    url: "/resource/case-study?title=esg-reporting-for-waste-management-companies",

    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 19, 2023",
    status: "5 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title:
      "Australia has unveiled the proposed climate-related reporting standards based on the IFRS framework.",
    title1: "November 2023",
    image: "images/nov2023-1.jpg",
    content:
      "Australia has unveiled proposed climate-related reporting standards, building on the International Sustainability Standards Board (ISSB) of the IFRS Foundation's sustainability disclosure standards.",
    url: "/resource/newsletter?month=november&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "November 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "GITEX GLOBAL Largest Tech & Startup Show in the World, Dubai World Trade Centre.",
    image: "Media/gitex-impact.jpg",
    content:
      "Updapt - an ESG Tech Co. is showcasing its cutting-edge Climate Tech solutions at GITEX IMPACT, GITEX GLOBAL Largest Tech & Startup Show in the World, Dubai World Trade Centre.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7119751006472581122",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 18, 2023",
    status: "5 min read",
    type: "media",
  },
  {
    title:
      "Updapt - an ESG Tech Co. was the ESG Tech Partner at 2nd Edition Sustainability Summit & Awards 2023.",
    image: "Media/sustainability-summit-and-awards-2023.jpg",
    content:
      "Updapt - an ESG Tech Co. was the ESG Tech Partner at 2d Edition Sustainability Summit & Awards 2023. Organised by UBS Forums Pvt. Ltd. at Taj MG Road, Bengaluru on 28th of Sept 2023 showcasing its innovative SaaS-Based ESG solution, empowering businesses to digitize and simplify their sustainability journey towards their netzero goals.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7113423863216037888",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 23, 2023",
    status: "4 min read",
    type: "media",
  },
  {
    title:
      "Ms. Maryam Telmesani, Saudi Arabia, joins Advisory Board of Updapt (an ESG Tech Co.)",
    image: "Media/maryam-telmesani-media.jpg",
    content:
      "Karnataka, India, 24th October 2023, ZEX PR WIRE, Updapt, a SaaS based ESG Solution Co., announced today that Ms. Maryam Telmesani has joined their Advisory Board to provide strategic guidance for solving key sustainability issues of the market it addresses and expansion in Middle East region.",
    url: "https://americantribune.co/ms-maryam-telmesani-saudi-arabia-joins-advisory-board-of-updapt-an-esg-tech-co/",
    websiteName: "americantribune.co",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 24, 2023",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title:
      "Updapt- An ESG Tech Co. Sustainability Spotlight Series - Mr. Ramveer Tanwar",
    image: "Media/spotlight-series-mr-ramveer-tanwar.jpg",
    content:
      "In a world where 2 billion people, constituting 26% of the global population, lack access to safe drinking water, and an alarming 3.6 billion individuals, or 46%, face the absence of safely managed sanitation, our shared responsibility for water conservation has never been more critical. ",
    url: "https://www.youtube.com/watch?v=vWQFel1mvV8",
    websiteName: "youtube.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "Nov 9, 2023",
    status: "22 min read",
    type: "media",
  },
  {
    title: "Details About ESG Data Management Software For 2024",
    url: "details-about-esg-data-management-software-for-2024",
    image: "Blogs/blog-29-image-1.jpg",
    content:
      "Updapt ESG Data Management Software helps all types of entities to manage their ESG Data Effectively and at the same time it gathers all emission data too.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Nov 17, 2023",
    status: "5 min read",
    type: "blogs",
  },
  {
    title:
      "Dutch Regulator AFM Urges Comprehensive Sustainability Impact Disclosures Across All Financial Products.",
    title1: "December 2023",
    image: "images/dec2023-1.jpg",
    content:
      "The Dutch Authority for the Financial Markets (AFM) has urged comprehensive sustainability impact disclosures for all financial products, regardless of their sustainability features.",
    url: "/resource/newsletter?month=december&year=2023",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "December 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "How Sebi's stringent sustainability reporting mandate is proving to be a challenge for top listed companies.",
    image: "Media/sebi-stringent-sustainability-reporting.png",
    content:
      "In 2021, the Securities and Exchange Board of India (Sebi) took a big leap in promoting sustainable practices in companies by introducing a framework for reporting such actions. These incorporated the environmental, social and governance (ESG) impacts of businesses in a bid to future-proof them.",
    url: "https://www.businesstoday.in/magazine/deep-dive/story/how-sebis-stringent-sustainability-reporting-mandate-is-proving-to-be-a-challenge-for-top-listed-companies-407240-2023-11-28#amp_tf=From%20%251%24s&aoh=17011932332893&referrer=https%3A%2F%2Fwww.google.com&ampshare=https%3A%2F%2Fwww.businesstoday.in%2Fmagazine%2Fdeep-dive%2Fstory%2Fhow-sebis-stringent-sustainability-reporting-mandate-is-proving-to-be-a-challenge-for-top-listed-companies-407240-2023-11-28",
    websiteName: "businesstoday.in",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "December 10, 2023",
    status: "4 min read",
    type: "newsroom",
  },
  {
    title:
      "Updapt - an ESG Tech Co.. was part of India Responsible Capital Conference (IRCC) 2023 at IIMA.",
    image: "Media/india-responsible-capital-conference-IRCC-2023.jpg",
    content:
      "Updapt - an ESG Tech Co.. was part of India Responsible Capital Conference (IRCC) 2023 at IIMA. IRCC-2023 was organized by the Arun Duggal Centre for ESG Research (CESGR) @ IIMA and the Misra Centre for Financial Markets and Economy at IIMA (MCFME), Indian Institute of Management Ahmedabad.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7143854552725848068",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "December 25, 2023",
    status: "2 min read",
    type: "media",
  },
  {
    title:
      "COP28 UAE, the 28th session of the UN Climate Change Conference of Parties,",
    image: "Media/cop28-uae.jpg",
    content:
      "COP28 UAE, the 28th session of the UN Climate Change Conference of Parties, is currently underway in the Expo City Dubai #UAE, drawing delegates from nearly 200 nations for a pivotal gathering aimed at formulating effective strategies against global warming.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7138788111429386240",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "December 25, 2023",
    status: "2 min read",
    type: "media",
  },
  {
    title:
      "ACCC Releases Eight Principles for Clear and Accurate Environmental Marketing Claims.",
    title1: "January 2024",
    image: "images/jan2024-1.jpg",
    content:
      "The Australian Competition and Consumer Commission (ACCC) has unveiled a set of eight principles designed to assist businesses in ensuring the transparency and accuracy of environmental marketing and advertising claims.",
    url: "/resource/newsletter?month=january&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "January 01, 2023",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Updapt's Satish Ramchandani on opportunities for the company's ESG software product",
    image: "Media/updapt's-satish-ramchandani.jpg",
    content:
      "Satish Ramchandani, co-founder and chief business officer of Updapt, gives us a quick update about the company which offers software solutions for businesses to manage their ESG, meaning environment, sustainability and governance, reporting.",
    url: "https://www.forbesindia.com/audio/forbes-india-tech-conver-sations/updapts-satish-ramchandani-on-opportunities-for-the-companys-esg-software-product/90797",
    websiteName: "forbesindia.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "January 11, 2024",
    status: "32 min read",
    type: "media",
  },
  {
    title: "Fast Moving Consumer Goods case study",
    image: "images/fmcgCase.jpg",
    content:
      "Client is a Singapore based conglomerate that has completed 75 years of successful operations, spanning over Asia, Africa and Europe, employing more than 20,000 people, across 10 plus countries, and reaching over one billion customers.",
    // url: "/resource/case-study/esg-reporting-for-fmcg-sector",
    url: "/resource/case-study?title=esg-reporting-for-fmcg-sector",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "January 31, 2024",
    status: "4 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title:
      "EBA Proposes Guidelines for Banks on ESG and Climate Transition Risk Management.",
    title1: "February 2024",
    image: "images/feb2024-1.jpg",
    content:
      "The European Banking Authority (EBA) has introduced proposed guidelines to empower banks in managing environmental, social, and governance (ESG) risks amid the transition to a climate-neutral economy.",
    url: "/resource/newsletter?month=february&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "February 01, 2024",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Transition Pathway Initiative (TPI): Streamlining the Low-Carbon Journey for businesses.",
    url: "transition-pathway-initiative",
    image: "Blogs/blog-30-thumbnail.png",
    content:
      "In the sphere of sustainability and corporate accountability, the Transition Pathway Initiative (TPI) emerges as a consequential force, providing invaluable guidance to businesses as they navigate the complexities of transitioning to the circular economy.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Feb 20, 2024",
    status: "3 min read",
    type: "blogs",
  },
  {
    title:
      "Updapt - an ESG Tech Co. Tech Co was invited to Leadership Summit 2024, organized by the SDA Bocconi Asia Center.",
    image: "Media/leadership-summit-2024.jpg",
    content:
      "As the demand for #sustainable business practices continues to grow, business schools are increasingly incorporating #sustainability into their curriculum including forming research cells on #ESG and inviting experts to share diverse perspectives with students on managing #climaterisks and related opportunities.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7161599626465210368",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "February 25, 2024",
    status: "2 min read",
    type: "media",
  },
  {
    title: "CFO Leadership Summit in Riyadh, Saudi Arabia.",
    image: "Media/cfo-leadership-summi-in-riyadh.jpg",
    content:
      "In rapidly evolving corporate landscape, companies worldwide are placing increasing importance on #ESG factors. Among key decision-makers, the Chief Financial Officer (CFO) plays a crucial role in guiding the organization's efforts toward #sustainability.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7165339795198468097",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "February 25, 2024",
    status: "2 min read",
    type: "media",
  },
  {
    title:
      "Kunal Raman, Director at Updapt - an ESG Tech Co., spoke about Sustainable Practices in the Mining Industry at the Odisha Mining and Infrastructure International Expo 2024 ",
    image: "Media/odisha-mining-and-infrastructure-international-expo-2024.jpg",
    content:
      "The #miningindustry is a critical contributor to the global economy. It provides necessary resources for various sectors, generates #employment, and boosts economies, contributing around 3-4% of the world's #GDP.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7166039668193538048",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "February 26, 2024",
    status: "3 min read",
    type: "media",
  },
  {
    title:
      "Japan Introduces Climate Transition Bond to Support Sustainability Goals.",
    title1: "March 2024",
    image: "images/mar2024-1.jpg",
    content:
      "The Japanese government recently announced the successful issuance of its inaugural Climate Transition Bond, raising JPY800 billion (USD$5.3 billion).",
    url: "/resource/newsletter?month=march&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "March 01, 2024",
    status: "3 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Updapt ESG Information Tech. LLC, in the #MiddleEast, ESG Summit 2024, Saudi Arabia.",
    image: "Media/esg-summit-updapt-llc-2024.jpg",
    content:
      "We are ecstatic to share that Updapt - an ESG Tech Co., has been honored with the Best #ESG Reporting, Management & Analytics tool Award at the ESG Summit 2024 in Saudi Arabia! ",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7168597015914921985",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "March 5, 2024",
    status: "3 min read",
    type: "media",
  },
  {
    title:
      "Updapt - an ESG Tech Co. participated as ESG Tech Partner at ESG Summit 2024, Saudi Arabia.",
    image: "Media/esg-summit-2024.jpg",
    content:
      "The cornerstone of #Saudi Arabia's approach to sustainability is encapsulated in Vision 2030, a strategic blueprint aimed at diminishing the country's reliance on oil by fostering the growth of public service sectors and nurturing a diverse, #sustainable economy. Integral to this vision is the Saudi Green Initiative, which sets forth ambitious environmental conservation and sustainable development targets.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7171066996879421441",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "March 12, 2024",
    status: "3 min read",
    type: "media",
  },
  {
    title:
      "Harnessing Technology for ESG: How SaaS Solutions Drive Sustainable Business Practices",
    image: "Media/harnessing-technology-for-esg.jpg",
    content:
      "The call for environmental, social, and governance (ESG) compliance in business reflects the growing awareness of the need for responsible and ethical economic growth.",
    url: "https://timestech.in/harnessing-technology-for-esg-how-saas-solutions-drive-sustainable-business-practices/",
    websiteName: "timestech.in",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "March 19, 2024",
    status: "3 min read",
    type: "media",
  },
  {
    title:
      "In a compelling live Television discussion on Mirror Now by Times Network The Urban Debate, Ajay kumar Anand, Director at Updapt - an ESG Tech Co.",
    image: "Media/water-crisis-mirror-now.jpg",
    content:
      "#Water, our most precious resource, sustains life, powers industries, and is indispensable for healthy ecosystems. Shockingly, nearly 2.2 billion people around the globe are deprived of access to safely managed drinking water. Despite ongoing efforts to enhance our water supply systems, a significant portion of urban cities in many countries continue to grapple with acute water stress including its quality. ",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7174429516549787650",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "March 19, 2024",
    status: "2 min read",
    type: "media",
  },
  {
    title: "National Green Infrastructure Conclave 2024",
    image: "Media/national-green-infrastructure-conclave-2024.jpg",
    content:
      "#Green #infrastructure plays a crucial role in #ecological preservation, contributing to both #social and #environmental #sustainability. Emphasized by the New Urban Agenda and the @Sustainable Development Goals (#SDGs), to build resilient infrastructure, promote #sustainable industrialization, and foster innovation.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7175816323300528131",
    websiteName: "linkedin.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "March 20, 2024",
    status: "2 min read",
    type: "media",
  },
  {
    title:
      "Choose Right Sustainability and ESG Software for Small Businesses in 2024",
    url: "esg-software-for-small-businesses-in-2024-updapt",
    image: "Blogs/blog-31-image-1.jpg",
    content:
      "As sustainability becomes a core component of business strategy, small businesses increasingly recognize the need to integrate Environmental, Social, and Governance (ESG) practices into their operations.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Mar 21, 2024",
    status: "4 min read",
    type: "blogs",
  },
  {
    title:
      "The Securities and Exchange Commission Enhances Climate-Related Disclosures for Companies.",
    title1: "April 2024",
    image: "images/apr2024-1.jpg",
    content:
      "The Securities and Exchange Commission (SEC) has adopted amendments to its rules under the Securities Act of 1933 and the Securities Exchange Act of 1934, mandating companies to furnish detailed climate-related information in their registration statements and annual reports.",
    url: "/resource/newsletter?month=april&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "April 01, 2024",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "Transform Your Business With Help of ESG software",
    url: "choose-wisely-esg-and-sustainability-reporting-software-for-your-business",
    image: "Blogs/blog-32-image-1.jpg",
    content:
      "Environmental, social, and governance metrics assist organizations to measure their sustainability performances. The prominent role of the best ESG software is to track, measure, and enhance the sustainability performance along with mitigating the risk. Many companies are experiencing their sustainability teams to struggle in choosing the ESG software for their businesses and at the same time be mindful of the criteria before buying an ESG software in 2024.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "Apr 17, 2024",
    status: "9 min read",
    type: "blogs",
  },
  {
    title:
      "FCA Introduces Expanded Sustainability Disclosure Rules for Portfolio Managers.",
    title1: "May 2024",
    image: "images/may2024-1.jpg",
    content:
      "The Financial Conduct Authority (FCA) has initiated steps to extend its Sustainability Disclosure Requirements (SDR) to include portfolio managers, broadening the scope of regulations initially focused on asset managers.",
    url: "/resource/newsletter?month=may&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 01, 2024",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Machine Learning & Predictive Analytics: A Game-Changer In The Fight Against Climate Change",
    image: "Media/machine-learning-and-predictive-analytics.png",
    content:
      "The climate economics index stress-tests reveal that climate change will impact 48 nations, encompassing 90% of the global economy, and evaluate their overall climate resilience",
    url: "https://inc42.com/resources/machine-learning-predictive-analytics-a-game-changer-in-the-fight-against-climate-change/",
    websiteName: "inc42.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 5, 2024",
    status: "2 min read",
    type: "newsroom",
  },
  {
    title:
      "Beyond Carbon Neutrality: A Look at the Multifaceted ESG Challenges Facing Indian Industry",
    image: "Media/beyond-carbon-neutrality.jpg",
    content:
      "The Indian industrial sector stands at a critical juncture. While achieving carbon neutrality remains a prominent environmental goal, a broader spectrum of environmental, social, and governance (ESG) concerns demands immediate attention. Embracing a holistic ESG framework is no longer a peripheral consideration; it is a strategic imperative for ensuring sustainable industrial growth and fostering India's global competitiveness.",
    url: "https://timestech.in/beyond-carbon-neutrality-a-look-at-the-multifaceted-esg-challenges-facing-indian-industry/",
    websiteName: "timestech.in",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 17, 2024",
    status: "2 min read",
    type: "newsroom",
  },
  {
    title:
      "Updapt | ESG Reporting Software For Real Estate Portfolio and Investors",
    url: "updapt-esg-reporting-software-for-real-estate-portfolio-and-investors",
    image: "Blogs/blog-33-image-1.jpg",
    content:
      "The growing necessity of ESG in various industries which includes real estate is quite prevalent. Investors, stakeholders and financial firms are seeking out organizations with potential ESG practices while comprehending the importance of ESG at the same time.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "May 22, 2024",
    status: "6 min read",
    type: "blogs",
  },
  {
    title:
      "In an Interview with Mr. Satish Ramchandani Co-Founder, Updapt an ESG Tech Co.",
    image: "Media/interview-with-mr-satish-ramchandani.png",
    content:
      "1. Can you provide an overview of Updapt's technological infrastructure and howit supports the tracking and monitoring of the ESG data lifecycle for enterprises and funds? Updapt offers advanced SaaS solutions for robust ESG data lifecycle management, catering to mid-sized and large enterprises, as well as investment funds globally. Our platform digitizes the entire ESG data management, monitoring, and reporting process, it also ensures alignment with international sustainability frameworks and standards such as GRI, SASB, CSRD, ESRS, BRSR, TCFD, CDP, DJSI and more.",
    url: "https://m.dailyhunt.in/news/india/english/republic+news+india-epaper-dhfacc36dfce9c4bb68db0e89d033c921b/in+an+interview+with+mr+satish+ramchandani+cofounder+updapt+an+esg+tech+co-newsid-dhfacc36dfce9c4bb68db0e89d033c921b_12586300198211efba3aed34fb9076c2?sm=Y",
    websiteName: "dailyhunt.in",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 23, 2024",
    status: "2 min read",
    type: "newsroom",
  },
  {
    title:
      "Algorithmic Governance: How AI Can Ensure ESG Compliance and Accountability",
    image: "Media/algorithmic-governance.png",
    content:
      "Putting Environment Social and Governance (ESG) criteria in corporate strategies has been the most essential element of creating sustainable companies that care about society and can make progress financially.",
    url: "https://www.techiexpert.com/algorithmic-governance-how-ai-can-ensure-esg-compliance-and-accountability/",
    websiteName: "techiexpert.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "May 28, 2024",
    status: "2 min read",
    type: "newsroom",
  },
  {
    title:
      "GRI Unveils 'CSRD Essentials' to Streamline EU Sustainability Reporting.",
    title1: "June 2024",
    image: "images/june2024-1.svg",
    content:
      "The Global Reporting Initiative (GRI) has introduced a new series titled 'CSRD Essentials' to help companies navigate the complexities of the EU's Corporate Sustainability Reporting Directive (CSRD).",
    url: "/resource/newsletter?month=june&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 01, 2024",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "ESG Tech Solutions for a Sustainable Future? Exclusive Chat With Satish Ramchandani of Updapt| Watch",
    image: "Media/solutions-for-a-sustainable-future.jpg",
    content:
      "Satish Ramchandani, an experienced finance professional, co-founded Updapt in 2021, an ESG tech firm offering innovative SaaS solutions for sustainability management. As Chief Business Officer, he is spearheading Updapt's mission to revolutionize ESG technology. In this interview, Ramchandani sheds light on Updapt's offerings and the significance of ESG in today's business landscape.",
    url: "https://www.youtube.com/watch?v=_b747N1rOtU",
    websiteName: "youtube.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 19, 2024",
    status: "12 min read",
    type: "media",
  },
  {
    title: "Facility Management case study",

    image: "images/facilityManagement.jpg",
    content:
      "Client is a multi-award-winning integrated facilities management provider in the UAE. It commands an exceptionally high level of client retention and has enjoyed continuous growth since its formation in 2002. Integrated facilities management (FM) provider, has implemented several targeted strategies and innovations to intensify efforts to provide sustainable FM solutions to its clients and support the UAE's vision to create a more sustainable environment. The organisation has launched 'Together for Sustainability' — a holistic approach to reducing the environmental impact of its operations through targeted sustainability programmes.",
    // url: "/resource/case-study/esg-reporting-for-facility-management",
    url: "/resource/case-study?title=esg-reporting-for-facility-management",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "June 26, 2024",
    status: "4 min read",
    type: "case study",
    sameTab: true,
  },
  {
    title: "Denmark Unveils World's First Agricultural Carbon Tax.",
    title1: "July 2024",
    image: "images/july2024-1.jpg",
    content:
      "Denmark has announced the world's first carbon tax targeting agricultural emissions. This initiative is part of the 'Green Tripartite' agreement, aiming to significantly reduce the country's greenhouse gas emissions.",
    url: "/resource/newsletter?month=july&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "July 01, 2024",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Updapt - An ESG Tech Co. partners with Grant Thornton Advisory East Africa to empower East African Businesses in their Sustainability Journey.",
    image: "Media/mediaTwo.jpg",
    content:
      "Updapt - An ESG Tech Co, announced an alliance with Grant Thornton Advisory East Africa Limited, part of Grant Thornton International Ltd, one of the leading Audit, Assurance, and Advisory companies, headquartered in London, UK, and operating across more than 149 countries.",
    url: "updapt-an-esg-tech-co-partners-with-grant-thornton-advisory-east-africa",
    websiteName: "updaptcsr.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "July 05, 2024",
    status: "2 min read",
    type: "newsroom-media",
  },
  {
    title:
      "Driving Sustainability: ESG Innovation in the Middle East | Syed Waqar Andrabi | #TGV510",
    image: "Media/driving-sustainability-in-the-middle-east.jpg",
    content:
      "Tune into #TGV510 to get clarity on the above topic. Here are the pointers from Syed Waqar's conversation with Naveen Samala on The Guiding Voice",
    url: "https://www.youtube.com/watch?v=vsUCLF6pRTU",
    websiteName: "youtube.com",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "July 6, 2024",
    status: "26 min read",
    type: "media",
  },
  {
    title: "ESG Software for manufacturing industries.",
    url: "esg-software-for-manufacturing-industries",
    image: "Blogs/blog-34-image-1.jpg",
    content:
      "Over the past 25 years, there has been a remarkable rapid transformation in the industrial landscape worldwide. The manufacturing industry (link to blog of Understanding Net Zero Emission Targets)  is also on the verge of such an industrial revolution, driven by increased competition, changing customer expectations, supply chain challenges, and natural challenges like global warming and climatic changes.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "July 24, 2024",
    status: "6 min read",
    type: "blogs",
  },
  {
    title: "UK Announces $10 Billion Platform for Clean Energy Development.",
    title1: "August 2024",
    image: "images/august2024-1.jpg",
    content:
      "The UK government has unveiled a significant initiative with the introduction of the Great British Energy Bill and the launch of a $10 billion platform dedicated to the development of clean energy projects. ",
    url: "/resource/newsletter?month=august&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "August 01, 2024",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "New Zealand Tightens Carbon Market with Emission Credit Reduction.",
    title1: "September 2024",
    image: "images/september2024-1.jpg",
    content:
      "New Zealand has recently revised its Emissions Trading Scheme (ETS) by reducing the number of available emission credits by half, aiming to intensify its efforts toward meeting its climate goals. This strategic adjustment is a response to the growing urgency to address climate change, aligning the country's policies with global standards.",
    url: "/resource/newsletter?month=september&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "September 01, 2024",
    status: "4 min read",
    type: "newsletter",
    sameTab: true,
  },

  {
    title: "ESG Software for the pharmaceutical industry.",
    url: "esg-software-for-pharmaceutical-industries",
    image: "Blogs/blog-35-img1.png",
    content:
      "Environmental, social, and governance (ESG) criteria are inevitable in today's modern business world.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "August 06, 2024",
    status: "9 min read",
    type: "blogs",
  },
  {
    title:
      "Hong Kong Proposes Global Standard Sustainability Reporting to Enhance Corporate Transparency.",
    title1: "October 2024",
    image: "images/october2024-1.jpeg",
    content:
      "Hong Kong is taking a significant step towards standardizing sustainability reporting by aligning its new reporting framework with global standards.",
    url: "/resource/newsletter?month=october&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "October 01, 2024",
    status: "7 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "What are the ESG Frameworks Have in ESG Reporting? Choose the Right One.",
    url: "esg-frameworks-have-in-esg-reporting",
    image: "Blogs/blog-36-img1.jpg",
    content:
      "ESG Reporting has numerous ESG Frameworks Includes BRSR, GRI,TCFD,CSRD,SASB.  It implies a set of comprehensive ESG guidance to help identify the ESG Risk.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "September 05, 2024",
    status: "7 min read",
    type: "blogs",
  },
  {
    title: "What is Carbon accounting : Know More About Carbon Accounting",
    url: "what-is-carbon-accounting",
    image: "Blogs/blog-37-img1.png",
    content:
      "Carbon accounting is measuring  green gas emission or carbon emission that is emitted by organizations. It includes direct and indirect emissions.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "October 26, 2024",
    status: "7 min read",
    type: "blogs",
  },
  {
    title:
      "Germany Allocates $3 Billion to Aid Industry Shift Towards Carbon Neutrality.",
    title1: "November 2024",
    image: "images/nov2024-1.jpeg",
    content:
      "Germany has allocated $3.1 billion to help decarbonize key industrial sectors such as chemicals, glass, and paper as part of its goal to become climate-neutral by 2045.",
    url: "/resource/newsletter?month=november&year=2024",
    author: "Deeksha",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "November 04, 2024",
    status: "7 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title:
      "Strategies to minimize the environmental impact of e-commerce logistics.",
    url: "minimize-the-environmental-impact-of-e-commerce-logistics",
    image: "Blogs/blog-38.jpg",
    content:
      "Minimizing the environmental impact of e-commerce logistics is more than just a necessity—it is a responsibility.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "November 15, 2024",
    status: "7 min read",
    type: "blogs",
  },
  {
    title: "ESG Data Management Software with ESG reporting.",
    url: "esg-data-management-software-for-uae",
    image: "Blogs/blog-39-1.png",
    content:
      "Ensure ESG data credibility and enhance Your ESG reporting with our ESG Data Management Software that is dedicatedly designed for the united emirate entities.",
    authorIimage: "Blogs/Deeksha.svg",
    date: "December 01, 2024",
    status: "7 min read",
    type: "blogs",
  },
  {
    title:
      "IPSASB Sets New Global Benchmark for Public Sector Climate Reporting Standards.",
    title1: "December 2024",
    image: "images/dec2024-1.jpeg",
    content:
      "The International Public Sector Accounting Standards Board (IPSASB) has released its inaugural exposure draft for Sustainability Reporting Standards, focusing on climate-related disclosures.",
    url: "/resource/newsletter?month=december&year=2024",
    author: "Veenapani Joshi",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "December 02, 2024",
    status: "7 min read",
    type: "newsletter",
    sameTab: true,
  },
  {
    title: "Switzerland Introduces Mandatory Net-Zero Roadmaps for Companies.",
    title1: "January 2025",
    image: "images/Thailand.jpeg",
    content:
      "The Swiss government has initiated a consultation to enhance corporate sustainability disclosure regulations, mandating that companies develop net-zero roadmaps aligned with the nation's goal of achieving net-zero greenhouse gas emissions by 2050.",
    url: "/resource/newsletter?month=january&year=2025",
    author: "Veenapani Joshi",
    authorIimage: "Blogs/Chinmayi.svg",
    date: "January 01, 2025",
    status: "7 min read",
    type: "newsletter",
    sameTab: true,
  },
];

export default resourceValues;
