import React from "react";
import MetaTags from "react-meta-tags";
import {
  getImage,
  getLink,
  buttonLink,
  demoTrial,
  getWebpImage,
  getNewsLetter,
} from "../../../../Common/functions";
import "../SCSS/styles.css";
import structuredData from "./structuredData.json";

function BlogForty() {
  return (
    <>
      <div className="blog-forty-container">
        <MetaTags>
          <title>
            What are the scope 1, scope 2, scope 3 and scope 4 carbon emissions? | updapt
          </title>
          <meta
            name="description"
            content="Awareness about climate change is burgeoning across many countries, including India."
          />
          <meta property="og:title" content="UpdaptCSR | Sustainability Platform" />
          <script type="application/ld+json" async>
            {JSON.stringify(structuredData)}
          </script>
        </MetaTags>
        <div className="blog-title">
          What are the scope 1, scope 2, scope 3 and scope 4 carbon emissions?
        </div>
        <div className="blog-author-details">
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Manikandan S</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">January 25, 2025</div>
              <div className="blog-read-time">7 min read</div>
            </div>
          </div>
        </div>

        <p>
          Awareness about climate change is burgeoning across many countries, including India.
          Humankind understands that climate change poses a significant threat to life on Earth.
          Meanwhile, businesses play a particularly significant role in climate change.
        </p>
        {getWebpImage("blog-40-1.webp", "esg for uae")}
        <p>
          <b>How do entities contribute to climate change?</b>
          <br />
          Diverse industrial sectors produce enormous carbon emissions. Day by day, these carbon
          emissions join the atmosphere, and the carbon footprint is increasing. This contributes
          significantly to climate change.
        </p>
        <p>
          Therefore, measuring and understanding Scope 1, Scope 2, and Scope 3 emissions are
          necessary for businesses to{" "}
          {getLink("achieve net zero", "/solutions/supplier-assessment")}
        </p>
        <br />
        <div className="multi-link-container">
          {buttonLink("Get free Trial", "/free-trial")}
          {buttonLink(
            "ESG Software for manufacturing",
            "esg-software-for-manufacturing-industries"
          )}
        </div>
        <br />
        <p>
          <h1>What is Scope 1, Scope 2, Scope 3, and Scope 4 carbon emission?</h1>
        </p>
        <p>
          <h2>Scope 1: Direct GHG Emission</h2>
        </p>
        <p>
          Scope 1 emissions refer to direct greenhouse gas (GHG) emissions that occur from sources
          owned or controlled by an organization within the boundaries of the organization.
        </p>
        {getWebpImage("blog-40-2.webp", "")}
        <p>
          <b>For instance:</b>
          <ul>
            <li>Emissions from the combustion of fossil fuels in on-site boilers or generators.</li>
            <li>Emissions from company-owned vehicles.</li>
            <li>Fugitive emissions from industrial processes.</li>
          </ul>
        </p>
        <p>
          <b>Scope 1 examples:</b>
        </p>
        <p>
          <ul>
            <li>
              <b>Stationary combustion: </b>This implies emissions resulting from burning fuels in
              stationary apparatus such as turbines, boilers, or generators. These carbon emissions
              are typically found in:
              <ul>
                <li className="circle">Power plants</li>
                <li className="circle">Industrial facilities</li>
                <li className="circle">Commercial buildings</li>
              </ul>
            </li>
            <li>
              <b>Mobile combustion: </b>This type of greenhouse gas emission emanates from burning
              fuel in all vehicles that are controlled by firms. For example: organization buses,
              trucks, and cars.
            </li>
            <li>
              <b>Process emissions: </b>Within a site, greenhouse gas emissions are released during
              manufacturing and chemical processing.
            </li>
            <li>
              <b>Fugitive emissions: </b>Fugitive emissions are either irregular greenhouse gas
              releases or gas leaks from industrial activities and processes. Fugitive emissions are
              not willfully emitted. They come from various sources of leaks and irregular releases.
            </li>
          </ul>
        </p>
        <br />
        <p>{buttonLink("Get monthly Newsletter", "/resource/newsletter")}</p>
        <br />
        <p>
          <h2>Carbon emissions of Scope 2</h2>
        </p>
        {getWebpImage("blog-40-3.webp", "")}
        <p>
          <b>Before entering the discussion on Scope 2 emissions:</b>
        </p>
        <p>
          Are you struggling to measure your carbon emissions? Would you like to develop an
          industry-specific strategy to reduce your carbon emissions? Do you want a clear picture of
          your carbon footprint?
        </p>
        <p>
          Scope 2 carbon emissions represent indirect emissions, which means they come from the
          generation of purchased electricity, heat, or steam consumed by the organization. These
          greenhouse gas emissions do not originate directly from the organization's operations but
          are associated with the activities that supply the energy the organization uses.{" "}
        </p>
        <p>
          <ul>
            <li>
              <b>Location-based target: </b>Location-based emission targets are calculated based on
              the grid mix where the electricity is used.{" "}
            </li>
            <li>
              <b>Market-based target: </b>Market-based emission targets are calculated based on the
              emissions associated with the electricity purchased, regardless of the grid mix.
            </li>
          </ul>
        </p>
        <br />

        <div className="multi-link-container">
          {buttonLink("ESG Software for pharma", "esg-software-for-pharmaceutical-industries")}
          {buttonLink("Get Free Demo", "/demo")}
        </div>

        <br />
        <p>
          <h2>Carbon emissions of Scope 3</h2>
        </p>
        {getWebpImage("blog-40-4.webp", "")}
        <p>
          In simple terms, Scope 3 emissions are produced throughout an organization's activities,
          such as its transportation and supply chain.
        </p>
        <p>
          Compared to Scope 1 and Scope 2 emissions, Scope 3 emissions can be more challenging to
          calculate accurately. If an organization has not measured Scope 3 emissions properly, it
          will be more complex to create enhanced ESG reports.
        </p>
        <p>
          According to the GHG Protocol, Scope 3 emissions are separated into fifteen categories.
          Each category has a different calculation method, and the organization should decide which
          calculation method is appropriate for its business based on its operations.
        </p>
        <p>Furthermore, categories are divided into upstream and downstream:</p>
        <p>
          <ul>
            <li>
              <b>Upstream: </b>This implies emissions are generated by activities in the
              organization's value chain before the organization receives the product or service.{" "}
            </li>
          </ul>
        </p>
        <p>
          <b>Category 1: Business travel:</b>
          <br />
          This category includes emissions emanating from employee business travel, which includes
          travel by bus, train, and air.
        </p>
        <p>
          <b>Category 2: Employee commuting:</b>
          <br />
          This category includes emissions due to employee travel to and from the office using
          various modes of transportation.
        </p>
        <p>
          <b>Category 3: Capital goods:</b>
          <br />
          This category includes emissions associated with the purchase of capital goods.
        </p>
        <p>
          <b>Category 4: Transportation and distribution:</b>
          <br />
          This category includes emissions generated during the transportation and distribution of
          materials to suppliers and customers.
        </p>
        <p>
          <b>Category 5: Waste generation:</b>
          <br />
          This category includes emissions generated from waste produced during business operations.
        </p>
        <p>
          <b>Category 6: Fuel and energy-related activities:</b>
          <br />
          These emissions do not fall under Scope 1 or Scope 2. They originate from the activities
          of processing, production, and transportation of fuels and energy.
        </p>
        <p>
          <b>Category 7: Fuel and energy-related activities:</b>
          <br />
          These emissions also do not fall under Scope 1 or Scope 2.
        </p>
        <p>
          <b>Category 8: Purchased goods and services:</b>
          <br />
          This category varies significantly based on the industry.
        </p>
        <p>
          <b>For instance:</b>
        </p>
        <p>
          If an organization is in the construction industry, 10 to 12% of emissions may come from
          purchased goods and services.
        </p>
        <p>
          Downstream represents emissions generated after goods and services have departed from the
          organization. It encompasses various aspects such as:
        </p>
        <p>
          <ul>
            <li>Processing of sold products</li>
            <li>Processing of sold products</li>
            <li>End-of-life treatment of sold products</li>
            <li>Downstream transportation and distribution</li>
            <li>Franchises</li>
            <li>Investments</li>
          </ul>
        </p>

        <br />
        <div className="multi-link-container">
          {buttonLink("Manage your ESG data effectively", "esg-data-management-software-for-uae")}
          {buttonLink("Customize Your ESG report", "/solutions/esg-reporting")}
        </div>
        <br />
        <p>
          <h2>What is meant by Scope 4 emission?</h2>
        </p>
        <p>
          Scope 4 emissions are not officially recognized under the GHG Protocol. However, the
          concept often refers to reducing emissions by encouraging consumers to use products more
          environmentally friendly.
        </p>
        <p>
          <h3>What is meant by the GHG Protocol?</h3>
        </p>
        <p>
          The GHG Protocol is a framework used by private organizations, businesses, and governments
          to measure their greenhouse gas emissions. It provides precise guidelines on how to
          calculate Scope 1, Scope 2, and Scope 3 emissions.
        </p>
        <br />
        <div className="multi-link-container">
          {buttonLink("Analyze Your ESG performance Deeply", "/solutions/esg-analytics")}
          {buttonLink("Manage Your ESG Risk", "/solutions/enterprise-risk-management")}
        </div>
        <br />
        <p>
          <h3>Ways to reduce scope emissions, which include Scope 4</h3>
        </p>
        <p>
          <b>For Scope 1:</b>
        </p>
        <p>
          <ul>
            <li>
              <b>Optimize machinery: </b>Many industrial machines rely on fossil fuels. Replacing
              older, less efficient machinery with more energy-efficient models can significantly
              reduce emissions consumption.
            </li>
            <li>
              <b>Use digital technology: </b>Many tools are available in the market, especially
              advanced carbon accounting tools, that can effectively help you measure your scope
              emissions accurately and provide industry-specific solutions to reduce them.
            </li>
          </ul>
        </p>
        <br />
        {buttonLink("Manage Your ESG work in a single platform", "/")}
        <br />
        <p>
          <h3>Ways to reduce scope emissions, which include Scope 4</h3>
        </p>
        <p>
          <b>For Scope 2:</b>
        </p>
        <p>
          <ul>
            <li>
              <b>Use Renewable Energy: </b>One of the most impactful ways to reduce Scope 2
              emissions is to invest in renewable energy sources. Running business operations using
              renewable energy is environmentally friendly and can also reduce operational costs.
            </li>
            <li>
              <b>Invest in green energy: </b>Due to organizational size or location constraints,
              installing renewable energy on-site may not always be feasible. Investing in Power
              Purchase Agreements (PPAs) is a great way to procure renewable energy from off-site
              sources and reduce Scope 2 emissions.
            </li>
          </ul>
        </p>
        <br />
        {buttonLink(
          "Transform Your Business  ",
          "choose-wisely-esg-and-sustainability-reporting-software-for-your-business"
        )}
        <br />
        <p>
          <b>For Scope 3:</b>
        </p>
        <p>
          <ul>
            <li>
              <b>Optimize transportation: </b>Utilize carbon-free vehicles and ensure suppliers
              adopt sustainable practices in their operations.
            </li>
          </ul>
        </p>
        <p>
          <b>For Scope 4:</b>
        </p>
        <p>
          <ul>
            <li>Promote the use of more environmentally friendly products.</li>
            <li>Switch to renewable energy sources for personal use.</li>
            <li>
              Engage with suppliers and partners to encourage their own sustainability efforts.
            </li>
          </ul>
        </p>
        <br />
        {buttonLink(
          "Sustainability and ESG Software for Small Businesses in 2025",
          "esg-software-for-small-businesses-in-2024-updapt"
        )}
        <br />
        <p>
          <h3>Why is measuring scope emissions important?</h3>
        </p>
        <p>
          Measuring scope emissions offers numerous benefits to organizations. Cost savings and
          improved brand reputation are significant benefits of{" "}
          {getLink("measuring scope emissions", "what-is-carbon-accounting")}. Apart from that:
        </p>
        <p>
          <ul>
            <li>It has become a necessity for many organizations.</li>
            <li>
              It allows for the easy identification of emission hotspots across business operations.
            </li>
            <li>
              It helps to attract potential investors who are increasingly interested in companies
              with strong sustainability performance.
            </li>
          </ul>
        </p>
        <br />
        {buttonLink("Know More about ESG frameworks", "esg-frameworks-have-in-esg-reporting")}
        <br />
        <p>
          <b>To Sum up:</b>
        </p>
        <p>
          Understanding and mitigating climate change are critical for the future of our planet.
          Businesses play a vital role in this effort.
        </p>
        <p>
          By accurately measuring and reducing their Scope 1, 2, and 3 emissions, organizations can
          contribute to a more sustainable future.
        </p>
        <p>
          Implementing strategies such as energy efficiency, renewable energy adoption, and supply
          chain decarbonization is crucial for achieving {getLink("net-zero", "/solutions/netzero")}{" "}
          goals and mitigating the impacts of climate change.
        </p>
      </div>
    </>
  );
}

export default BlogForty;
